import { io } from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_URL;

    const tokenPin = localStorage.getItem("mamaketUserToken");
const socketOptions = {
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: "Bearer " + tokenPin,
      },
    },
  },
  reconnection: false, // This line adds reconnection:false to the socketOptions object
};

const socket = io(URL, socketOptions);
socket.on("connect", () => {
  console.log("connected", "This is socket");
});

export default socket;
