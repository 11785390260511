import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../../assets/Products/1.png";
import nigFlag from "../../../assets/Flags/Japan.svg";
import "../../../STYLES/main.css";
import whatsapp from "../../../assets/Icons/whatsapp 1.svg";
import twitter from "../../../assets/Icons/twitter 1 (1).svg";
import facebook from "../../../assets/Icons/facebook 1 (1).svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import love from "../../../assets/Icons/love.svg";
import { add } from "../../../store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import Loader from "../../Common/Loader";
import { GET_SINGLE_PRODUCT } from "../../../Utils/Queries";
import noImage from "../../../assets/no-image.png";
import { formatDistanceToNow } from "date-fns";
import { increaseQuantity, decreaseQuantity } from "../../../store/cartSlice";
import { addFav } from "../../../store/favouriteSlice";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MakeOffer from "./MakeOffer";
import { addToCartAsync, fetchCartAsync, } from "../../../store/cartAsync";
import CartAlert from "../../Common/AlertModals/CartAlert";

const Overview = (productID) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(false);
  const [productImage, setProductImage] = useState(img1);
  const [productQuantity, setProductQuantity] = useState(1);
  const cartProducts = useSelector((state) => state.cart);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleIncreaseQuantity = (id) => {
    dispatch(increaseQuantity(id));

    setProductQuantity(productQuantity + 1);
  };
  const handleDecreaseQuantity = (id) => {
    dispatch(decreaseQuantity(id));
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
    }
  };

  const handleAddToCart = () => {
    setIsLoading(true);
    // Example of adding an item to the cart
    const payload = { product: productID.productID, quantity: productQuantity };
    dispatch(addToCartAsync(payload))
      .then(() => {
        // alert("succcessfull")
        setIsLoading(false);
        setSuccess(true);
      })
      .catch((err) => {
        console.log("Error");
        setIsLoading(false);
      });
  };

  const handleSuccessChange = (data) =>{
    setSuccess(data)
  }

    useEffect(() => {
      if (success) {
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      }
    }, [success]);
  useEffect(() => {
    // Fetch cart items when the component mounts
    dispatch(fetchCartAsync());
  }, [dispatch]);

  const { loading, error, data } = useQuery(GET_SINGLE_PRODUCT, {
    variables: {
      _id: productID?.productID,
    },
  });

  const product = data?.listAllAvailableProductsWithoutAuthentication?.items[0];
  console.log(product);

  // const addCart = (product) => {
  //   dispatch(add(product));
  // };
  const handleaddFav = (product) => {
    dispatch(addFav(product));
  };

  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const handleToLogin = () => {
    navigate("/login");
  };

  return (
    <>
      {loading || (isLoading && <Loader />)}
      {success && (
        <CartAlert open={success} handleClose={handleSuccessChange} />
      )}

      <Box
        sx={{
          p: 1,
          px: 2,
          bgcolor: "#fff",
          fontFamily: "inter",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link
            to="/"
            style={{ fontWeight: 400, color: "#666", fontFamily: "inter" }}
          >
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
                fontFamily: "inter",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Home
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Link to="/">
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
                fontFamily: "inter",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Products
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Typography
            sx={{
              color: "#333",
              fontWeight: 500,
              fontFamily: "inter",
              fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
            }}
          >
            {product?.categories[0]?.name}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "100%",
          bgcolor: "#fff",
          mt: { lg: 5, md: 5, sm: 0, xs: 0 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column-reverse",
                    xs: "column-reverse",
                  },
                }}
              >
                <Grid
                  item
                  lg={1.5}
                  md={1.5}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "column",
                      md: "column",
                      sm: "row",
                      xs: "row",
                    },
                    gap: 2,
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={10.5} md={10} sm={12} xs={12}>
                  <Avatar
                    src={product?.images[1] ? product?.images[1] : noImage}
                    variant="square"
                    sx={{ width: "100%", height: "400px", borderRadius: 1 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Info Product */}
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{}}>
              <Box sx={{}}>
                <Box
                  sx={{
                    px: "12px",
                    py: "0px",
                    borderBottom: "1px solid #f2f2f2",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                      Product Origin:
                    </Typography>
                    <img
                      src={
                        product?.country?.flagUrl
                          ? product?.country?.flagUrl
                          : nigFlag
                      }
                      width={20}
                    />
                  </Box>
                  <Typography
                    // className="product_title"
                    sx={{
                      fontSize: {
                        lg: "26px",
                        md: "26px",
                        sm: "20px",
                        xs: "20px",
                        color: "#333",
                        fontWeight: 500,
                      },
                      mt: 1,
                    }}
                  >
                    {product?.name}
                  </Typography>
                  <Box sx={{ mt: 1.5, display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{ fontSize: "16px", color: "#333", fontWeight: 500 }}
                    >
                      &#36; {product?.price.toLocaleString()}
                    </Typography>
                    {product?.isNegotiable === true ? (
                      <Typography
                        sx={{
                          bgcolor: "#E9FCF9",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Negotiable
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          bgcolor: "#E9FCF9",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Buy Now
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: { lg: 2, md: 2, sm: 1, xs: 1 },
                      color: "#333",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      {`${product?.city?.name}, ${product?.city?.state?.name}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Views: 200
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Posted:{" "}
                      {product?.createdAt &&
                        formatDistanceToNow(new Date(product?.createdAt), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: "1px solid #f2f2f2",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: "20px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: {
                        lg: "row",
                        md: "row",
                        sm: "column",
                        xs: "column",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#333",
                        mr: 1,
                        ml: 1,
                      }}
                    >
                      Share with friends
                    </Typography>
                    <Box>
                      <IconButton>
                        <img src={facebook} alt="facebook_icon" />
                      </IconButton>
                      <IconButton>
                        <img src={twitter} alt="twitter_icon" />
                      </IconButton>
                      <IconButton>
                        <img src={whatsapp} alt="whatsapp_icon" />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box>
                    {product?.isNegotiable === true ? (
                      <MakeOffer product={product} quantity={productQuantity} />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    py: "24px",
                    px: "12px",
                    borderBottom: "1px solid #f2f2f2",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#333", fontSize: "12px" }}
                  >
                    Quantity :
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      padding: "5px 12px",
                      display: "flex",
                      alignItems: "center",
                      ml: 2,
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "#333",
                        fontSize: "12px",
                        padding: "2px",
                        fontWeight: 100,
                      }}
                      onClick={() => handleDecreaseQuantity(product._id)}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#333",
                        fontSize: "12px",
                        mx: 1,
                      }}
                    >
                      {productQuantity}
                    </Typography>
                    <IconButton
                      sx={{ color: "#333", fontSize: "12px", padding: "2px" }}
                      onClick={() => handleIncreaseQuantity(product._id)}
                    >
                      <AddOutlinedIcon sx={{ fontWeight: 100 }} />
                    </IconButton>
                  </Box>
                </Box>

                <Box
                  sx={{
                    pt: "24px",
                    px: { lg: "12px", md: "12px", xs: "0px", sm: "0px" },
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: {
                        lg: "350px",
                        md: "300px",
                        sm: "100%",
                        xs: "100%",
                      },
                      borderRadius: "100px",
                      padding: "10px 20px",
                    }}
                    onClick={isAuthenticated ? handleAddToCart : handleToLogin}
                  >
                    Add to cart
                  </Button>
                  <Button
                    onClick={
                      isAuthenticated
                        ? () => handleaddFav(product)
                        : handleToLogin
                    }
                    sx={{ color: "#333", fontSize: "12px" }}
                  >
                    <img
                      width={30}
                      src={love}
                      alt="fav_icon"
                      style={{ marginRight: "10px" }}
                    />
                    Save For Later
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
