import React, {useEffect} from 'react'
import Navbar from '../../Components/Common/Navbar'
import FullSearchBar from '../../Components/Search/FullSearchBar'
import { Box, Container } from '@mui/material'
import Carousel from '../../Components/Common/Carousel'
import Footer from '../../Components/Common/Footer'
import CategoriesBanner from '../../Components/Common/CategoriesBanner'
import LatestProducts from '../../Components/Products/LatestProducts'
import ServiceListings from '../../Components/Services/ServiceListings'
import TopSeller from '../../Components/Products/TopSeller'
import Newsletter from '../../Components/Common/Newsletter'
import SellsBanner from '../../Components/Common/SellsBanner'
import MamaketOffer from '../../Components/Common/MamaketOffer'
import NewsBlog from '../../Components/Blog/NewsBlog'
import { useDispatch } from 'react-redux'
import {getProducts}  from '../../store/productSlice'

const Home = () => {

      const dispatch = useDispatch();

      useEffect(() => {
        dispatch(getProducts());
      }, []);
  return (
    <>

        <Navbar />
        <Box sx={{ mt: {lg:13, md:12, sm:8, xs:8} }}>
          <FullSearchBar />
        </Box>
        <Carousel />
        <CategoriesBanner />
        <Box sx={{margin:'0 auto', width:{lg:'87%', md:'87%', sm:'100%', xs:'100%'}, mt: 3 }}>
          <LatestProducts />
          <ServiceListings />
          <SellsBanner />
          <TopSeller />
          <MamaketOffer />
          <NewsBlog />
        </Box>
        <Newsletter />
        <Footer />

    </>
  );
}

export default Home
