import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
Popover,
Popper,
List,
ListItemButton
} from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ClearIcon from '@mui/icons-material/Clear';
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import CategoryMenu from "../Categories/CategoryMenu";
import PostalCode from "../Common/PostalCode";
import { useLazyQuery } from "@apollo/client";
import { GENERAL_SEARCH } from "../../Utils/Queries";





const FullSearchBar = () => {

  const theme = useTheme()
const [query, setQuery] = useState("")
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
// console.log(query)

const [anchorEl, setAnchorEl] = React.useState(null);
const [open, setOpen] = React.useState(false);

const handleChange= (event) => {
  setQuery(event.target.value)
  if(query.length > 1){
  setOpen((previousOpen) => true);
  setAnchorEl(event.currentTarget);
  }


};
const [handleSearch, { loading, data }] = useLazyQuery(GENERAL_SEARCH);
console.log(data?.searchInput);
useEffect(()=>{
if (query.length < 3) {
  setAnchorEl(null);
   setOpen((previousOpen) => false);
}
handleSearch({
  variables:{
    query
  }
})
}, [query])




const handleClose = () => {
  setAnchorEl(null);
};


  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
// console.log(anchorEl, open);
  return (
    <>
      <Box
        sx={{
          bgcolor: "#f2f2f2",
          pt: 2,
          pb: { xs: 0, sm: 0, md: 2, lg: 2 },
          display: "flex",
          alignItems: "center",
        }}
      >
        {isMobile ? (
          <>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", px: 2 }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={query}
                  onChange={handleChange}
                  placeholder="Search for a product or service"
                  InputProps={{
                    style: {
                      fontFamily: "inter",
                      fontSize: "13px",
                      borderRadius: "50px",
                      background: "#fff",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <IconButton>
                        {query.length > 0 ? (
                          <ClearIcon onClick={() => setQuery("")} />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PostalCode />
                <Link to="/download">
                  <Button
                    sx={{
                      color: "#1a1a1a",
                      fontFamily: "inter",
                      fontSize: "12px",
                    }}
                  >
                    Become A Seller
                  </Button>
                </Link>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            sx={{
              margin: "0 auto",
              width: "85%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <CategoryMenu />
            </Box>
            <Box sx={{ width: "50%", display: "flex" }}>
              <Box aria-describedby={id} sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={query}
                  onChange={handleChange}
                  placeholder="Search for a product or service"
                  InputProps={{
                    style: {
                      fontFamily: "inter",
                      fontSize: "13px",
                      borderRadius: "50px",
                      background: "#fff",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <IconButton>
                        {query.length > 0 ? (
                          <ClearIcon onClick={() => setQuery("")} />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>

              <PostalCode />
            </Box>
            <Box>
              <Link to="/download">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "40px",
                    background: "#1a1a1a",
                    "&:hover": { background: "#1a1a1ab7" },
                  }}
                >
                  Become A Seller
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>

      <Popper
        transition
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 160, left: 500 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                zIndex: 1000,
                p: 2,
                bgcolor: "#fff",
                mt: 2,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            >
              {data && data?.searchInput?.length === 0 ? (
                <>
                  <Typography sx={{ color: "#23263B", fontWeight: 600 }}>
                    Sorry, <br />
                    we didn’t find any matches for "{query}"
                  </Typography>
                  <Typography sx={{ mt: 1.5, color: "#333", fontWeight: 400 }}>
                    Try the following:
                  </Typography>
                  <ul className="no_search_list">
                    <li>Double check your spelling</li>
                    <li>Use fewer keywords</li>
                    <li>
                      Search fo an item that is less specific and refine results
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <List sx={{ width: "450px" }}>
                    {data?.searchInput.map((item, index) => (
                      <Link
                        to={
                          item.source === "Product"
                            ? `/product-view/${item?.id}`
                            : `/service-view/${item?.id}`
                        }
                        key={index}
                      >
                        <ListItemButton>
                          <SearchSharpIcon sx={{ color: "#333" }} />
                          <Typography
                            sx={{ ml: 2, color: "#333", fontSize: "13px" }}
                          >
                            {item?.result}
                          </Typography>
                          <Typography
                            variant="label"
                            sx={{ fontSize: "11px", color: "#b3b3b3", ml: 1 }}
                          >{`(${item?.source})`}</Typography>
                        </ListItemButton>
                      </Link>
                    ))}
                  </List>
                </>
              )}
            </Box>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default FullSearchBar;
