import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import ProductsCard from '../../Cards/ProductsCard'
import img1 from '../../../assets/Products/1.png'
import img2 from '../../../assets/Products/2.png'
import img3 from '../../../assets/Products/3.png'
import img4 from '../../../assets/Products/4.png'
import img5 from '../../../assets/Products/5.png'


const data = [
    {
        img:img1,
        price:'80.00'
    },
    {
        img:img2,
        price:'80.00'
    },
    {
        img:img3,
        price:'80.00'
    },
    {
        img:img4,
        price:'80.00'
    },

]
const SimilarProducts = () => {
  return (
  <>
  <Box sx={{bgcolor:'#fff', margin:'0 auto ', width:'100%', mt:2, mb:4}}>
    <Box sx={{p:2}}>
<Typography variant='h6' sx={{fontSize:'16px', color:'#1a1a1a', fontFamily:'inter', fontWeight:500}}>Similar Products</Typography>

<Grid container spacing={2}>
    {
data.map(({img, price})=>(
    <Grid item lg={2.5} xs={6} md={3}>
    <ProductsCard image={img} price={price}/>
</Grid>
))
    }
 
</Grid>
    </Box>
  </Box>
  </>
  )
}

export default SimilarProducts
