import { gql } from "@apollo/client";

export const SIGNUP_MUTATION = gql`
  mutation SignUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
    $password: String!
    $lat: Float!
    $long: Float!
    $country: String!
    $favouriteCategories: [String!]!
  ) {
    signUp(
      signUpInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        password: $password
        lat: $lat
        long: $long
        country: $country
        favouriteCategories: $favouriteCategories
      }
    )
  }
`;

export const SIGNIN_SOCIAL = gql`
  mutation loginSocial($accessToken:String! $socialAuthProviderKey: String!) {
    loginThroughSocialAuth(
      payload: {
        accessToken: $accessToken
        socialAuthProviderKey: $socialAuthProviderKey
      }
    )
  }
`;

export const RESEND_OTP = gql`
  mutation ResendOTP($email: String!) {
    resendOTP(emailInput: { email: $email })
  }
`;

export  const RESET_PASSWORD = gql`
mutation reset($otp:String! $uuid: String! $password:String!) {
    resetPasswordWithOTP(passwordResetInput: {
        otp: $otp
        uuid: $uuid
        password: $password
    })
}
`

export const VALIDATE_OTP = gql`
  mutation ValidateOTP($uuid: String!, $otp: String!) {
    validateOTP(validateOtpInput: { uuid: $uuid, otp: $otp })
  }
`;

export const CREATE_PIN = gql`
  mutation CreateTransactionPin($userId: String!, $transactionPin: String!) {
    createTransactionPinOnSignUp(
      createTransactionPinInput: {
        userId: $userId
        transactionPin: $transactionPin
      }
    )
  }
`;

export const LOGIN_MUTATION = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(loginPayload: { email: $email, password: $password })
  }
`;

export const PROFILE_EDIT = gql`
  mutation updateProfileInformation(
    $firstName: String!,
    $lastName: String!,
    $sex: String!,
    $country: String!,
  ) {
    updateProfileInformation(
      payload: {
        firstName: $firstName,
        lastName: $lastName,
        sex: $sex,
        country: $country,
      }
    ) {
      uuid
      firstName
      lastName
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      payload: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      firstName
      lastName
    }
  }
`;

export const CHANGE_PIN = gql`
  mutation changeTransactionPin(
    $currentPassword: String!
    $currentTransactionPin: String!
    $newTransactionPin: String!
  ) {
    changeTransactionPin(
      payload: {
        currentPassword: $currentPassword
        currentTransactionPin: $currentTransactionPin
        newTransactionPin: $newTransactionPin
      }
    ) {
      firstName
      lastName
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation addNewAddress(
    $firstName: String!
    $lastName: String!
    $country: String!
    $state: String!
    $city: String!
    $addressOne: String!
    $addressTwo: String
    $zipCode: String!
    $phone: String!
    $isDefault: Boolean!
  ) {
    addNewAddress(
      payload: {
        firstName: $firstName
        lastName: $lastName
        country: $country
        state: $state
        city: $city
        addressOne: $addressOne
        addressTwo: $addressTwo
        zipCode: $zipCode
        phone: $phone
        isDefault: $isDefault
      }
    )
  }
`;

export const ADD_FUNDS = gql`
  mutation initializeWalletFunding($amount: Float!) {
    initializeWalletFunding(
      payload: {
        amount: $amount
        gatewayProviderClass: "stripe.service"

      }
    )
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder(
    $deliveryOption:String!
       
    $addressOne:String!
    $country:String!
  $state:String!
  $addressTwo:String!
  $city:String!
  $zipCode:String!
         
  $transactionPin:String!
    ) {
    createOrder(
      payload: {
        deliveryOption: $deliveryOption
        shippingAddress: {
          addressOne: $addressOne
          country: $country
          state: $state
          addressTwo:$addressTwo
          city: $city
          zipCode: $zipCode
        }
        transactionPin: $transactionPin
      }
    )
  }
`;

export const ADD_CARTS = gql`
  mutation addToCart($product:String!, $quantity:Float!) {
    addToCart(payload: { product:$product , quantity: $quantity }) {
      _id
      quantity
      pricePerUnit
      totalAmount
      createdAt
      updatedAt
      product {
        _id
        name
        description
        images
      }
      session {
        _id
        currency {
          name
          shortCode
          shortCode
        }
      }
    }
  }
`;

export const REMOVE_CARTS = gql`
  mutation removeItems($itemIds:String!) {
    removeItems(payload: { itemIds: [$itemIds] })
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelProduct($itemId:String! $reason:String! $description:String!){
    cancelProductOrderByBuyer(
      payload: {
        itemId:$itemId
        reason: $reason
        description: $description
      }
    ) {
      message
    }
  }
`;

