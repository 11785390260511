import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import verify from '../../assets/Icons/verify 1.svg'
import { Link } from 'react-router-dom';

const Confirmation = () => {
  return (
    <>
      <Box sx={{ display: "grid", placeItems: "center", height: "300px" }}>
        <Box align="center">
          <img src={verify} />
          <Typography
            sx={{ fontSize: "20px", color: "#1a1a1a", fontWeight: 500 }}
          >
            Order Successful
          </Typography>
          <Typography sx={{ color: "#1a1a1a", fontSize: "13px" }}>
            Your order has been placed, ID: F587553RRF
          </Typography>
          <Link to="/">
            <Button variant="contained" sx={{ mt: 3, borderRadius: "100px" }}>
              Back to homepage
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
}

export default Confirmation
