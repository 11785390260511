import React from 'react'
import Navbar from '../Components/Common/Navbar'
import Newsletter from '../Components/Common/Newsletter'
import Footer from '../Components/Common/Footer'
import { Box, Button, Grid, TextField, Typography, useTheme } from '@mui/material'
import mail from '../assets/Icons/mail.svg'
import location from '../assets//Locations 1.jpg'
import phone from '../assets/Icons/phone 1.svg'
import locationIcon from '../assets/Icons/location.svg'

const Contact = () => {
  const theme = useTheme()
  return (
    <>
      <Navbar />
      <Box
        sx={{
          margin: "0 auto",
          width:{lg: "85%", md:'85%', sm:'95%', xs:'95%'},
          mt:{lg: 17, md:17, sm:10, xs:10},
          backgroundImage: `url('${location}')`,
          mb: 5,
        }}
      >
        <Grid container sx={{ p:{lg: 3, md:3, sm:2, xs:2} }} columnSpacing={5}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box
              sx={{
                bgcolor: "#fff",
                backgroundSize: "cover",
                backgroundPosition: "center",
                p: 2,
                borderRadius: 0.5,
              }}
            >
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Full Name
              </Typography>
              <TextField fullWidth sx={{ mb: 2 }} />
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Email Address
              </Typography>
              <TextField fullWidth sx={{ mb: 2 }} />
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Message
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="your message"
                sx={{ mb: 2 }}
              />

              <Button
                variant="contained"
                sx={{ mt: 3, borderRadius: "50px" }}
                fullWidth
              >
                Send Message
              </Button>
            </Box>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Box>
              <Typography
                sx={{ fontSize: "36px", color: "#F8F7F7", fontWeight: 500 , mt:{lg:0, md:0, sm:7, xs:7}}}

              >
                Contact Us
              </Typography>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  p: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={mail} alt="email_icon" />
                <Box>
                  <Typography sx={{ color: "#fff" }}>Email Support</Typography>
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    care@mamaket.com
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  p: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={phone} alt="email_icon" />
                <Box>
                  <Typography
                    sx={{ color: "#fff", fontWeight: 500, fontSize: "14px" }}
                  >
                    Phone Support
                  </Typography>
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    Call line: (786) 998-1929
                  </Typography>
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    Fax: (786) 998-1931
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  p: 2,
                  alignItems: "center",
                  columnGap: 2,
                }}
              >
                <img src={locationIcon} alt="email_icon" />
                <Box>
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#fff" }}
                  >
                    Office Address
                  </Typography>
                  <Typography sx={{ color: "#fff", fontSize: "16px" }}>
                    848 Brickell Avenue <br />
                    Penthouse 5 Miami,
                    <br /> FL 33131 USA
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <Newsletter /> */}
      <Footer />
    </>
  );
}

export default Contact