import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ProductsCard from '../Cards/ProductsCard'
import { useQuery } from '@apollo/client'
import { GET_TOPSELLERS_PRODUCTS, GET_TOPSELLERS_PRODUCTS_AUTH } from '../../Utils/Queries'
import { Link } from 'react-router-dom'
import Loader from '../Common/Loader'


const TopSeller = () => {

    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
    
    const { loading, data} = useQuery(isAuthenticated ? GET_TOPSELLERS_PRODUCTS_AUTH : GET_TOPSELLERS_PRODUCTS)
    
    const topSellerProducts = isAuthenticated ? data?.getMarketPlaceBestSellingProductListing :
      data?.getMarketPlaceBestSellingProductListingForUnAuthenticatedUser;
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2, pb: 4, my: 3 }}>
        <Typography variant="subtitle2" sx={{fontSize:{lg:'24px', xs:'20px', md:'24px', sm:'20px'}}}>Top Sellers</Typography>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {topSellerProducts?.slice(0, 10).map((product, index) => (
              <Grid item lg={2.4} md={3} sm={4} xs={6} key={index}>
                <Link to={`/product-view/${product._id}`} key={index}>
                  <ProductsCard
                    image={product.images && product.images[0]}
                    price={product.price.toLocaleString()}
                  />
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default TopSeller
