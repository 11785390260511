import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import Newsletter from '../../Components/Common/Newsletter'
import Footer from '../../Components/Common/Footer'
import { Box, Grid, Typography } from '@mui/material'
import barcode from '../../assets/barcode.png'

const Download = () => {
  return (
<>
<Navbar/>
<Box sx={{pt:{lg:17, md:17, sm:5, xs:5}, bgcolor:'#fff', }}>
    <Box sx={{margin:'0 auto', width:{lg:'70%', md:'70%', sm:'90%', xs:'90%'}, mt:7,pb:7}}>
<Typography variant='subtitle2' sx={{textAlign:'center', fontWeight:600}}>Start Selling On Mamaket Today</Typography>
<Box >
    <Grid container sx={{mt:4}}>
        <Grid item lg={6} md={6} sm={12}  xs={12} sx={{display:'flex', flexDirection:'column', alignItems:{lg:'initial', md:'initial', sm:'center', xs:'center'}, justifyContent:'space-between'}}>
            <Box sx={{width:{lg:'80%', md:'80%', sm:'85%', xs:'85%'}, mb:{lg:0, md:0, sm:4,xs:4}}} className="download_one">
<Typography variant='h4'>Download & Create</Typography>
<Typography variant='body1'>Simply download Mamaket & sign up on, by providing the required information.</Typography>
            </Box>
            <Box sx={{width:{lg:'80%', md:'80%', sm:'85%', xs:'85%'}, mb:{lg:0, md:0, sm:4,xs:4}}} className="download_two">
<Typography variant='h4'>List Products & Services</Typography>
<Typography variant='body1'>Effortlessly list your products/services on Mamaket in just 3 simple steps.</Typography>
            </Box>
            <Box sx={{width:{lg:'80%', md:'80%', sm:'85%', xs:'85%'}, mb:{lg:0, md:0, sm:4,xs:4}}} className="download_three">
<Typography variant='h4'>Make Sales</Typography>
<Typography variant='body1'>Experience the power of making sales like never before!</Typography>
            </Box>
        </Grid>
        <Grid item lg={6} xs={12}>
            <Box>
<img src={barcode} width="100%" />
            </Box>
        </Grid>
    </Grid>
</Box>
    </Box>
</Box>
<Newsletter/>
<Footer/>
</>
  )
}

export default Download
