import React from "react";
import Navbar from "../../Components/Common/Navbar";
import BreadCrumbs from "../../Components/Common/BreadCrumbs";
import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ProductData } from "../../assets/Data/ProductData";
import ProductsCard from "../../Components/Cards/ProductsCard";
import { Link } from "react-router-dom";

const CategoryView = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ margin: "0 auto", width: "90%", mt: 15 }}>
        <BreadCrumbs />
        <Box sx={{ mt: 2, bgcolor: "#fff" }}>
          <Box sx={{ p: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: "#1a1a1a",
                fontWeight: 500,
                fontFamily: "inter",
              }}
            >
              Category
            </Typography>

            <Grid container sx={{ mt: 0 }} spacing={3}>
              <Grid item lg={3}>
                <Box sx={{ border: "1px solid #e6e6e6", padding: "12px" }}>
                  <Typography
                    sx={{ color: "#1b1b1b", fontWeight: 600, fontSize: "24px" }}
                  >
                    Women
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                    <FormControlLabel control={<Checkbox />} label="All" />
                    <FormControlLabel control={<Checkbox />} label="Sweater" />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Sweater & Blouses"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Sweater & Blouses"
                    />
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Sweater & Blouses"
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        color: "#1b1b1b",
                        fontWeight: 600,
                        fontSize: "16px",
                      }}
                    >
                      Sort by
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Price, low to high"
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Price, low to high"
                      />
                    </Box>
                  </Box>

                  <Box sx={{ mt: 3 }}>
                    <Button sx={{ color: "#FF3347" }}>Clear all</Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={9}>
                <Box>
                    <Grid container spacing={2}>
                        {
                            ProductData.map((item, _id)=>(
                                <Grid item lg={3}>
                                    <Link to="/product-view">
                                    <ProductsCard image={item.img} price={item.price}/>
                                    </Link>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default CategoryView;
