import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/productSlice";
import Loader from "../Common/Loader";
import StatusCode from "../../Utils/StatusCode";
import ProductsCard from "../Cards/ProductsCard";

const LatestProducts = () => {
  const { data, status } = useSelector((state) => state.products);

  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2, pb: 4, my: 3, mx: 0 }}>
        <Typography variant="subtitle2" sx={{fontSize:{lg:'24px', xs:'20px', md:'24px', sm:'20px'}}}>Latest Products</Typography>
        <Box>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {data === undefined ? (
              <p>No Data</p>
            ) : (
              data.slice(0, 10).map((product, index) => (
                <Grid item lg={2.4} md={3} sm ={4} xs={6} key={product._id}>
                  <Link to={`/product-view/${product._id}`} key={index}>
                    <ProductsCard
                      image={product.images && product.images[0]}
                      price={product.price.toLocaleString()}
                      flag={product?.country?.flagUrl}
                    />
                  </Link>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LatestProducts;
