import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'

const OfferCards = ({image, title, caption}) => {
  return (
<>
<Box>
    <Avatar variant='square' sx={{width:'100%', height:'230px'}} src={image}/>
    <Box sx={{mt:1}}>
        <Typography         variant="caption"
              sx={{ fontFamily: "16px", fontWeight: 500 }}>{title}</Typography>
              <br/>
        <Typography         variant="caption"
              sx={{ fontFamily: "12px", fontWeight: 200 }}>{caption}</Typography>

    </Box>
</Box>
</> 
  )
}

export default OfferCards
