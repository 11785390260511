import "./STYLES/main.css";
import React, { useEffect } from "react"; // Import React
import { Provider } from "react-redux";
// import { useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Container, createTheme, ThemeProvider } from "@mui/material";
import { ApolloProvider } from "@apollo/client";
import { Routes } from "./Routes";
import ScrollToTop from "./ScrollToTop";
import { store, persistor } from "./store/store";
import client from "./Utils/ApolloInstance";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useDispatch } from "react-redux";
import { logoutUser } from "./store/userSlice";
import socket from "./Utils/socket";
import { fetchCart } from "./store/cartsSlice";
import { fetchCartAsync } from "./store/cartAsync";

const theme = createTheme({
  palette: {
    primary: {
      main: "#148B77",
    },
  },
  typography: {
    h1: {
      fontFamily: "syne",
      fontWeight: 700,
      fontSize: "110px",
      color: "#fff",
      lineHeight: { lg: "98px", xs: "50px" },
    },
    h2: {
      fontFamily: "inter",
      fontWeight: 300,
      color: "#fff",
      fontSize: "20px",
      lineHeight: "30px",
    },
    button: {
      fontFamily: "inter",
      textTransform: "initial",
    },
    caption: {
      fontFamily: "inter",
      fontWeight: 400,
      fontSize: "12px",
      color: "#1a1a1a",
    },
    subtitle1: {
      fontFamily: "poppins",
      color: "#000",
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: "34px",
    },
    subtitle2: {
      fontFamily: "inter",
      color: "#1a1a1a",
      fontWeight: 500,
      fontSize: "30px",
      // lineHeight:'34px'
    },
    h3: {
      fontFamily: "syne",
      fontWeight: 700,
      color: "#333333",
      fontSize: "64px",
    },
    h5: {
      fontFamily: "inter",
      fontWeight: 400,
      color: "#1a1a1a",
      fontSize: "14px",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "inter",
      fontWeight: 600,
      color: "#1a1a1a",
      fontSize: "20px",
    },
    body1: {
      fontFamily: "inter",
      fontWeight: 300,
      fontSize: "15px",
      color: "#00000080",
      lineHeight: "28px",
      letterSpacing: "0.01em",
    },
  },
});

function SessionTimer() {
  const token = localStorage.getItem("tokenExpiresIn");
  const dispatch = useDispatch();
  // console.log(storeUser)
  const history = useNavigate();

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  useEffect(() => {
    if (isAuthenticated) {
   
          dispatch(fetchCartAsync());
    }
  }, []);

  // Retrieve the token expiration time from your user store
  const tokenExpiresIn = token; // Replace with your actual value in seconds

  useEffect(() => {
    // Calculate the expiration time in milliseconds
    if (token !== null) {
      const expirationTime = tokenExpiresIn * 1000;

      // Set up a timer to navigate to the login page when the session expires
      const timer = setTimeout(() => {
        dispatch(logoutUser());
        // alert("Token Expired");
        history("/login");
        localStorage.removeItem("tokenExpiresIn");
      }, expirationTime);

      // Clean up the timer when the component unmounts
      return () => clearTimeout(timer);
    }
  }, [history, tokenExpiresIn]);

  return null; // This component doesn't render anything visible
}

function App() {

  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <ApolloProvider client={client}>
              <ScrollToTop />
              <SessionTimer />
              <Routes />
            </ApolloProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
