import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {

      const user = useSelector(
        (state) => state.user
      );
    
    console.log(user, "with sammy")

  if (user.isAuthenticated === false || user.user === null) {
    // You might want to check other criteria as well, like user role, token expiration, 
    // Redirect the user to the login page if not authenticated
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;