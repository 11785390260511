import React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import LoadButton from "../../../Components/Common/LoadButton";
import { useMutation } from "@apollo/client";
import { CHANGE_PIN } from "../../../Utils/Mutations";
import { useFormik } from "formik";
import * as Yup from "yup";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import passwordIcon from "../../../assets/Icons/password 1.svg";
import SuccessAlert2 from "../../../Components/Common/AlertModals/SuccessAlert2";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("PIN is required")
    .matches(/^\d{4}$/, "PIN must be exactly 4 digits"),
  confirmPassword: Yup.string()
    .required("Confirm PIN is required")
    .oneOf([Yup.ref("password"), null], "PINs must match"),
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: 400, md: 400, sm: 300, xs: 300 },
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

const ChangePin = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };
  const handleClose = () => setOpen(false);
  const [success, setSuccess] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [currentPin, setCurrentPin] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [changePassword, { loading, error, data }] = useMutation(CHANGE_PIN);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setSuccess(false);
      changePassword({
        variables: {
          currentPassword: currentPassword,
          currentTransactionPin: currentPin,
          newTransactionPin: values.password,
        },
      })
        .then((response) => {
          setSuccess(true);
        alert("Weeeeee")
          console.log(response);
        })
        .catch((error) => {
          setSuccess(false);
          console.log(error);
        });
      console.log(values);
    },
  });

  return (
    <>
      <IconButton onClick={handleOpen}>
        <ChevronRightIcon sx={{ color: "#333" }} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
              >
                Change Transaction PIN
              </Typography>
              <Typography
                sx={{ fontWeight: 200, color: "#333", fontSize: "13px" }}
              >
                Enter a new 4 digit pin to authorize all transactions.
              </Typography>
            </Box>

            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#333" }} />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Current Password"
              margin="normal"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",

                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type={showPassword ? "number" : "password"}
              placeholder="Current pin"
              margin="normal"
              value={currentPin}
              onChange={(e) => setCurrentPin(e.target.value)}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",

                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="New Pin"
              margin="normal"
              error={errors.password}
              helperText={errors.password}
              id="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                style: {
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Confirm Pin"
              margin="normal"
              error={errors.confirmPassword}
              helperText={errors.confirmPassword}
              id="confirmPassword"
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                style: {
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
         
              <SuccessAlert2
                img={passwordIcon}
                open={success}
                url="account"
                header="Transaction PIN Changed"
                text="Proceed to enjoy your shopping experience"
                button="Done"
              />
            
            {error && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,
                  fontFamily: "inter",
                  fontSize: "12px",
                }}
              >
                {error.message}
              </Typography>
            )}
            <LoadButton
              text="Reset Password"
              text2="Resetting password"
              action={handleSubmit}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ChangePin;
