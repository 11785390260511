import {
  Box,
  Tabs,
  Tab,
  Typography,
  Chip,
  Avatar,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import emptyCart from "../../../assets/Icons/empty_cart.svg";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1.5, bgcolor: "#F8F7F7" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ServiceOrder = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: "#fff", p: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All" sx={{ fontFamily: "inter" }} {...a11yProps(0)} />
              <Tab label="Confirmed" {...a11yProps(1)} />
              <Tab label="Shipped" {...a11yProps(2)} />
              <Tab label="Delivered" {...a11yProps(3)} />
              <Tab label="Cancelled" {...a11yProps(4)} />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <All />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          Item Two
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          Item Three
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          Item Three
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
};

export default ServiceOrder;

const All = () => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data.map((item) => (
          <Link to="/account/orders/order-details">
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    sx={{ width: "80px", height: "100%" }}
                  />
                  <Box>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      #1234567
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Jordan 1 Retro High Tie Dye
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: 1
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        $80.00
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Chip label="confirmed" />
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
    </>
  );
};

const data = [1, 2, 3, 4];
