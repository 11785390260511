import React from 'react'
import { Box , Typography, Grid} from '@mui/material'
import BlogCard from '../Cards/BlogCard'
import blogImg from '../../assets/blog.png'


const newsData = [
{
    img:blogImg,
    title:'Blog Post Title',
    caption:"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu..."
},
{
    img:blogImg,
    title:'Blog Post Title',
    caption:"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu..."
},
{
    img:blogImg,
    title:'Blog Post Title',
    caption:"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu..."
},
{
    img:blogImg,
    title:'Blog Post Title',
    caption:"At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu..."
},
]

const NewsBlog = () => {
  return (
<>
<Box sx={{ py:2, px:{lg:0, md:0, sm:2, xs:2}}}>
 <Typography variant='subtitle2' sx={{fontSize:{lg:'24px', xs:'20px', md:'24px', sm:'20px'}}}>News Updates</Typography>
 <Grid container spacing={4} sx={{mt:0}}>
    {
        newsData.map(({img, title, caption}, index)=>(
            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
      <BlogCard image={img} title={title} caption={caption}/>
    </Grid>
        ))
    }
 </Grid>
 </Box>
</>
  )
}

export default NewsBlog
