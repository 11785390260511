import {
  Box,
  Tabs,
  Tab,
  Typography,
  Chip,
  Avatar,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import emptyCart from "../../../assets/Icons/empty_cart.svg";
import { Link } from "react-router-dom";
import { GET_BUYER_ORDERS } from "../../../Utils/Queries";
import Loader from "../../../Components/Common/Loader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1.5, bgcolor: "#F8F7F7" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ProductOrder = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [getAddress, {loading, data:dats}] = useLazyQuery(GET_BUYER_ORDERS)
  useEffect(()=>{
getAddress()
  }, [])

  console.log(dats?.getMyOrders.items, "Orders");
  return (
    <>
      {loading && <Loader />}
      <ThemeProvider theme={theme}>
        <Box sx={{ bgcolor: "#fff", p: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="All" sx={{ fontFamily: "inter" }} {...a11yProps(0)} />
              <Tab label="Confirmed" {...a11yProps(1)} />
              <Tab label="Shipped" {...a11yProps(2)} />
              <Tab label="Delivered" {...a11yProps(3)} />
              <Tab label="Cancelled" {...a11yProps(4)} />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <All data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Confirmed />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Shipped />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Delivered />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Cancelled data={dats?.getMyOrders?.items} />
        </CustomTabPanel>
      </ThemeProvider>
    </>
  );
};

export default ProductOrder;

const All = ({data}) => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data.map((item) => (
          <Link
            to={
              item.status === "Canceled"
                ? " "
                : `/account/orders/order-details/${item.reference}`
            }
          >
            <MenuItem
              disabled={item.status === "Canceled"}
              key={item.id}
              sx={{ bgcolor: "#fff", p: 1, mt: 1 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.status === "Paid" && <Chip label="confirmed" />}
                  {item?.status == "Canceled" && (
                    <Chip
                      label="Canceled"
                      sx={{ bgcolor: "#FFE5E8b7", color: "#FF4C5E" }}
                    />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
    </>
  );
};
const Confirmed = ({data}) => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data.map((item) => (
          <Link to={`/account/orders/order-details/${item.reference}`}>
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.statusHistory[0].status === "Paid" && (
                    <Chip label="confirmed" />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
    </>
  );
};
const Shipped = ({data}) => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data.map((item) => (
          <Link to={`/account/orders/order-details/${item.reference}`}>
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.statusHistory[0].status === "Paid" && (
                    <Chip label="confirmed" />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
    </>
  );
};
const Delivered = ({data}) => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data.map((item) => (
          <Link to={`/account/orders/order-details/${item.reference}`}>
            <MenuItem key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: 2,
                    width: "fit-content",
                  }}
                >
                  <Avatar
                    variant="square"
                    src={item?.product.images[0]}
                    sx={{ width: "80px", height: "70px" }}
                  />
                  <Box>
                    <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                      {item.reference}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1a1a1a",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item?.product?.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                        }}
                      >
                        QTY: {item?.quantity}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "12px",
                          fontWeight: 400,
                          ml: 3,
                        }}
                      >
                        ${item?.amountPaid.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  {item?.statusHistory[0].status === "Paid" && (
                    <Chip label="confirmed" />
                  )}
                </Box>
              </Box>
            </MenuItem>
          </Link>
        ))}
    </>
  );
};
const Cancelled = ({data}) => {
  const [empty, setEmpty] = useState(null);
  return (
    <>
      {!data && (
        <Box sx={{ height: "300px", display: "grid", placeItems: "center" }}>
          <img src={emptyCart} alt="empty_cart" />
        </Box>
      )}
      {data &&
        data
          .filter((item) => item.status === "Canceled")
          .map((item) => (
            <Link to="">
              <MenuItem disabled key={item.id} sx={{ bgcolor: "#fff", p: 1, mt: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: 2,
                      width: "fit-content",
                    }}
                  >
                    <Avatar
                      variant="square"
                      src={item?.product.images[0]}
                      sx={{ width: "80px", height: "70px" }}
                    />
                    <Box>
                      <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                        {item.reference}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "14px",
                          fontWeight: 600,
                        }}
                      >
                        {item?.product?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          QTY: {item?.quantity}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "12px",
                            fontWeight: 400,
                            ml: 3,
                          }}
                        >
                          ${item?.amountPaid.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Chip
                      label="canceled"
                      sx={{ bgcolor: "#FFE5E8b7", color: "#FF4C5E" }}
                    />
                  </Box>
                </Box>
              </MenuItem>
            </Link>
          ))}
    </>
  );
};

// const data = [1, 2, 3, 4];
