import {
  Avatar,
  Box,
  Rating,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import shippingIcon from "../../../assets/Icons/shipping.svg";
import returnIcon from "../../../assets/Icons/return.svg";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_SERVICE } from "../../../Utils/Queries";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Description = ({ serviceID }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = React.useState(0);
  console.log("IDD", serviceID);
  const { loading, error, data } = useQuery(GET_SINGLE_SERVICE, {
    variables: {
      _id: serviceID, // Replace with the actual dynamic _id value
    },
  });

  console.log(
    data,
    "=>DES PRODUCT"
  );

  const product = data?.listAllAvailableServicesWithoutAuthentication?.items[0];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ margin: " 0 auto", width: "100%", bgcolor: "#fff", mt: 1 }}>
        <Box sx={{ padding: "24px 24px" }}>
          {isMobile ? (
            <>
              <Box>
                <div>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: 400, color: "#333" }}>
                        Description
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#4d4d4d",
                          fontWeight: 400,
                          my: 1.5,
                          fontStyle: "italic",
                        }}
                      >
                        {" "}
                        {product?.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 400, color: "#333" }}>
                        Review
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ my: 1.5 }}>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Avatar sx={{ width: "50px", height: "50px" }} />
                          <Box>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography
                                sx={{
                                  color: "#1a1a1a",
                                  fontSize: "15px",
                                  fontWeight: 500,
                                }}
                              >
                                Jaxson Aminoff
                              </Typography>
                              <Typography
                                sx={{ color: "#999", ml: 2, fontSize: "13px" }}
                              >
                                Jan 12, 2088
                              </Typography>
                            </Box>
                            <Rating
                              readOnly
                              value={product?.rating}
                              size="small"
                            />
                            <Typography
                              sx={{ fontSize: "13px", color: "#666" }}
                            >
                              At vero eos et accusamus et iusto odio dignissimos
                              ducimus qui blanditiis praesentium voluptatum
                              deleniti atque corrupt et quas molestias excepturi
                              sint non provident, sunt{" "}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 400, color: "#333" }}>
                        Shipping
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ my: 1.5 }}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                          <img src={shippingIcon} alt="shipping_icon" />
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              Delivery Time
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "12px" }}
                            >
                              Estimate delivery time 1-7 days
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 400, color: "#333" }}>
                        Return Policy & Warranty
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ my: 1.5 }}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                          <img src={returnIcon} alt="shipping_icon" />
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "16px",
                                fontWeight: 600,
                              }}
                            >
                              7 Day Return Guarantee
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "12px" }}
                            >
                              For more information on the return shipping
                              options, go to{" "}
                              <Link to="/" style={{ color: "#148B77" }}>
                                Mamaket Shipping Policy
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          sx={{
                            color: "#4d4d4d",
                            fontSize: "12px",
                            fontWeight: 400,
                          }}
                        >
                          Warranty information unavailable for this item.
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    sx={{
                      boxShadow: "none",
                      "&::before": { bgcolor: "transparent" },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography sx={{ fontWeight: 400, color: "#333" }}>
                        Seller Information
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ my: 1.5 }}>
                        <Box sx={{ display: "flex", columnGap: 2 }}>
                          <Avatar sx={{ width: "40px", height: "40px" }} />
                          <Box>
                            <Typography
                              sx={{
                                color: "#1a1a1a",
                                fontSize: "17px",
                                fontWeight: 400,
                              }}
                            >
                              {`${product?.merchant?.firstName} ${product?.merchant?.lastName}`}
                            </Typography>
                            <Typography
                              sx={{ color: "#666", fontSize: "12px" }}
                            >
                              City, State
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Box>
            </>
          ) : (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Description" />
                  <Tab label={`Review (0)`} />
                  <Tab label="Shipping" />
                  <Tab label="Return Policy & Warranty" />
                  <Tab label="Seller Information" />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#4d4d4d",
                    fontWeight: 400,
                    my: 1.5,
                  }}
                >
                  {" "}
                  {product?.description}
                </Typography>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Avatar sx={{ width: "50px", height: "50px" }} />
                    <Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          sx={{
                            color: "#1a1a1a",
                            fontSize: "15px",
                            fontWeight: 500,
                          }}
                        >
                          Jaxson Aminoff
                        </Typography>
                        <Typography
                          sx={{ color: "#999", ml: 2, fontSize: "13px" }}
                        >
                          Jan 12, 2088
                        </Typography>
                      </Box>
                      <Rating readOnly value={product?.rating} size="small" />
                      <Typography sx={{ fontSize: "13px", color: "#666" }}>
                        At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui blanditiis praesentium voluptatum deleniti
                        atque corrupt et quas molestias excepturi sint non
                        provident, sunt{" "}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <img src={shippingIcon} alt="shipping_icon" />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        Delivery Time
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px" }}>
                        Estimate delivery time 1-7 days
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <img src={returnIcon} alt="shipping_icon" />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "16px",
                          fontWeight: 600,
                        }}
                      >
                        7 Day Return Guarantee
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px" }}>
                        For more information on the return shipping options, go
                        to{" "}
                        <Link to="/" style={{ color: "#148B77" }}>
                          Mamaket Shipping Policy
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    sx={{ color: "#4d4d4d", fontSize: "12px", fontWeight: 400 }}
                  >
                    Warranty information unavailable for this item.
                  </Typography>
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <Box sx={{ my: 1.5 }}>
                  <Box sx={{ display: "flex", columnGap: 2 }}>
                    <Avatar sx={{ width: "50px", height: "50px" }} />
                    <Box>
                      <Typography
                        sx={{
                          color: "#1a1a1a",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {`${product?.merchant?.firstName} ${product?.merchant?.lastName}`}
                      </Typography>
                      <Typography sx={{ color: "#666", fontSize: "12px" }}>
                        City, State
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Description;
