import React, {useState, useEffect} from 'react'
import { Box, Button, Modal , Typography, InputLabel, TextField, Chip} from '@mui/material'
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Loader from './Loader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:400, md:400, sm:350, xs:300},
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
  };
const API_KEY = process.env.REACT_APP_GOOGLE_KEY;
const PostalCode = () => {
    const userLocation = JSON.parse(localStorage.getItem('postalCode'));

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const [isError, setIsError] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const [postalCode, setPostalCode] = useState('');

    const [placeName, setPlaceName] = useState('');

    const handlePostalCodeChange = (event) => {
        setPostalCode(event.target.value);
      };
    
  const handlePostalCodeSubmit = async (event) => {
  setIsLoading(true);
  event.preventDefault();


  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${postalCode}&key=${API_KEY}`
    );

    if (response.ok) {
      setIsLoading(false);
      const data = await response.json();
      console.log(data, "Postal code");
      if (data.results.length > 0) {
        const { formatted_address } = data.results[0];
        const placeName = formatted_address;
        localStorage.setItem('postalCode', JSON.stringify(placeName));
        setPlaceName(placeName);
        setOpen(false);
      } else {
        setIsLoading(false);
        setIsError(true);
        setPlaceName('Place not found');
      }
    } else {
      setIsLoading(false);
      setPlaceName('Error retrieving place');
    }
  } catch (error) {
    setIsLoading(false);
    setPlaceName('Error retrieving place');
    console.log(error);
  }
};


      useEffect(()=>{
      if (userLocation === null){
setOpen(true)
      }
      else{
        setPlaceName(userLocation)
      }

        // setOpen(true)
      },[])
    
      
  return (
<>
<Box onClick={handleOpen} sx={{display:'flex', alignItems:'center', ml:1}}>
<LocationOnIcon sx={{ fontSize: "15px" }} />
<Chip label={placeName} sx={{fontFamily:'inter', fontWeight:500, fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, bgcolor:{sm:'transparent', xs:'transparent'}}}/>
</Box>



            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography sx={{fontSize:'20px', color:'#1a1a1a', fontWeight:500}}>Enter your location</Typography>
        <Typography sx={{fontSize:'14px', color:'#666', fontWeight:300}}>Find local products near you. Enter your zipcode.</Typography>
{
    isLoading && <Loader/>
}

        <Box sx={{ mt: 3 }}>
            {/* <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                // fontFamily: "Gordita",
              }}
            >
              Email
            </InputLabel> */}
            <TextField
              placeholder="Enter Postal/Zip Code"
              size="large"
              fullWidth
              helperText={isError && "Place Not Found"}
              error={isError}
              type='number'
              value={postalCode} onChange={handlePostalCodeChange}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
           
            </Box>
<Button variant='contained' fullWidth onClick={handlePostalCodeSubmit} sx={{mt:3, borderRadius:'100px'}}>Check</Button>
          
          </Box>
      </Modal>
</>
  )
}

export default PostalCode
