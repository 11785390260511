import img1 from '../Products/1.png'
import img2 from '../Products/2.png'
import img3 from '../Products/3.png'
import img4 from '../Products/4.png'
import img5 from '../Products/5.png'
import img6 from '../Products/6.png'
import img7 from '../Products/7.png'
import img8 from '../Products/8.png'
import img9 from '../Products/9.png'
import img10 from '../Products/10.png'

   


export const ProductData = [
  {
    img: img1,
    price: "80.00",
    title: "Jordan 1 Retro High Tie Dye",
  },
  {
    img: img2,
    price: "140.00",
    title: "Jordan 1 Retro High Tie Dye",
  },
  {
    img: img3,
    price: "90.00",
    title: "Jordan 1 Retro High Tie Dye",
  },
  {
    img: img4,
    price: "30.00",
    title: "Jordan 1 Retro High Tie Dye",
  },
  {
    img: img5,
    price: "100.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
  {
    img: img6,
    price: "80.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
  {
    img: img7,
    price: "80.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
  {
    img: img8,
    price: "80.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
  {
    img: img9,
    price: "80.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
  {
    img: img10,
    price: "20.00",
     title:'Jordan 1 Retro High Tie Dye'
  },
];