import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ServiceDrawer = () => {
  return (
    <div>
      <Accordion sx={{ mb: 2, border: "none", boxShadow: "none", p: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#333" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{}}
        >
          <Typography
            sx={{ fontWeight: 500, color: "#333", fontFamily: "inter" }}
          >
            Automotive Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: -3 }}>
          <Typography>subtitle2</Typography>
          <Typography>subtitle2</Typography>
          <Typography>subtitle2</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 2, border: "none", boxShadow: "none", p: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#333" }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{}}
        >
          <Typography
            sx={{ fontWeight: 500, color: "#333", fontFamily: "inter" }}
          >
            IT Services
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ mt: -3 }}>
          <Typography>subtitle2</Typography>
          <Typography>subtitle2</Typography>
          <Typography>subtitle2</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ServiceDrawer;
