import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import OfferCards from '../Cards/OfferCards'
import img1 from '../../assets/Offer/1.png'
import img2 from '../../assets/Offer/2.png'
import img3 from '../../assets/Offer/3.png'
import img4 from '../../assets/Offer/4.png'

const offerData = [
    {
        img:img1,
        title:'Best Quality Guarantee',
        caption:'Product that arrived at your door already passed our Quality Control procedure.'
    },
    {
        img:img2,
        title:'Easy Payment Choice',
        caption:'Various payment choice will give an ease every time you purchase our product'
    },
    {
        img:img3,
        title:'On-Time Delivery',
        caption:'We will make sure that all product that you purchased will arrived at your address safely'
    },
    {
        img:img4,
        title:'Free Return',
        caption:'If you are unsatisfied with our product, we will responsible for return fee of our product'
    },
]

const MamaketOffer = () => {
  return (
 <>
 <Box sx={{ py:2, px:{lg:0, md:0, sm:2, xs:2}}}>
 <Typography variant='subtitle2'  sx={{fontSize:{lg:'24px', xs:'20px', md:'24px', sm:'20px'}}}>Mamaket Value offers</Typography>
 <Grid container spacing={4} sx={{mt:0}}>
    {
        offerData.map(({img, title, caption}, index)=>(
            <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
        <OfferCards image={img} title={title} caption={caption}/>
    </Grid>
        ))
    }
 </Grid>
 </Box>
 </>
  )
}

export default MamaketOffer
