import { createSlice } from "@reduxjs/toolkit";
import client from "../Utils/ApolloInstance";
import { ADD_CARTS } from "../Utils/Mutations";
import { GET_CART } from "../Utils/Queries";
import { fetchUpdateCart } from "./fetchUpdateCart";

const initialState = [];

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add(state, action) {
      const productToAdd = action.payload;
      //   const product = product.quantity
      const existingProduct = state.find(
        (item) => item._id === productToAdd._id
      );

      if (existingProduct) {
        // If the product already exists in the cart, increase its quantity
        existingProduct.quantity += 1;
      } else {
        // If the product is not in the cart, add it with quantity 1
        state.push({ ...productToAdd, quantity: 1 });
      }
    },
    remove(state, action) {
      return state.filter((item) => item._id !== action.payload);
    },
    increaseQuantity(state, action) {
      const productId = action.payload;
      const product = state.find((item) => item._id === productId);
      if (product) {
        product.quantity += 1;
      }
    },
    decreaseQuantity(state, action) {
      const productId = action.payload;
      const product = state.find((item) => item._id === productId);
      if (product && product.quantity > 1) {
        product.quantity -= 1;
      }
    },
    updateCartAsync: (state, action) => {
      // Extract product id and quantity from the payload
      // const { productId, quantity } = action.payload;
      const productId = action.payload;
      const product = state.find((item) => item?._id === productId);
      console.log(product)

      // Send the updated data to the endpoint
      
       client
         .mutate({
           mutation: ADD_CARTS,
           variables: {
             product:product?._id,
             quantity:product?.quantity,
           },
         })
         .then((response) => {
           // Handle success if needed
           console.log("Updated cart data:", response.data);
           // You can dispatch a regular action if necessary
           // dispatch(updateCartSuccess(response.data));
         })
         .catch((error) => {
           // Handle error if needed
           console.error("Error updating cart data:", error);
           // You can dispatch a regular action if necessary
           // dispatch(updateCartFailure(error));
         });
    },


  },
  // extraReducers: (builder) => {
  //   builder.addCase(updateCartAsync.fulfilled, (state, action) => {
  //     // Handle the fulfilled action (after updateCartAsync completes successfully)
  //     // Call fetchUpdatedCart to get the updated cart data
  //     fetchUpdateCart();
  //   });
  // }
});

export const { add, remove, increaseQuantity,  updateCartAsync, decreaseQuantity } =
  cartSlice.actions;
export default cartSlice.reducer;


