import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import Newsletter from '../../Components/Common/Newsletter'
import Footer from '../../Components/Common/Footer'
import { Box, Button, Typography, Divider } from '@mui/material'
import google from "../../assets/Icons/googs.svg";
import facebook from "../../assets/Icons/Facebook.svg";
import apple from "../../assets/Icons/bi_apple.svg";
import { Link } from 'react-router-dom'

const RegisterMain = () => {
  return (
    <>
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Welcome
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 200, fontSize: "14px" }}>
            Please enter your detail
          </Typography>
          <Link to="/register-form/">
            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={{ borderRadius: "100px", mt: 3 }}
            >
              Sign Up with email
            </Button>
          </Link>
          <Divider sx={{ mt: 3 }}>
            <Typography>OR</Typography>
          </Divider>

          <Box sx={{ mt: 3 }}>
            <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={google} style={{ marginRight: "10px" }} />
              Sign in with Google
            </Button>
            <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={apple} style={{ marginRight: "10px" }} />
              Sign in with Apple ID
            </Button>
            <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={facebook} style={{ marginRight: "10px" }} />
              Sign in with Facebook
            </Button>
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
}

export default RegisterMain