// cartSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../Utils/ApolloInstance';
import { ADD_CARTS, REMOVE_CARTS } from '../Utils/Mutations';
import { GET_CART } from '../Utils/Queries';

// GraphQL Mutations and Queries
const addToCartMutation =ADD_CARTS
const removeItemsMutation = REMOVE_CARTS
const getMyShoppingCartQuery =GET_CART

// Async Thunks for GraphQL Operations
export const addToCartAsync = createAsyncThunk(
  "cart/addToCart",
  async (payload) => {
    try {
      const response = await client.mutate({
        mutation: addToCartMutation,
        variables: {
          product: payload.product,
          quantity: payload.quantity,
        },
      });
      return response.data.addToCart;
    } catch (error) {
      console.error("Error adding to cart:", error);
      throw error;
    }
  }
);


export const removeItemsAsync = createAsyncThunk('cart/removeItems', async (itemIds) => {
  try{
    const response =   await client.mutate({ mutation: removeItemsMutation, variables: {itemIds } });
  
  return response
  }catch(error){
console.log(error)
throw error;
  }

  return itemIds;
});

export const fetchCartAsync = createAsyncThunk('cart/fetchCart', async () => {
  const response = await client.query({ query: getMyShoppingCartQuery });

  console.log(response, "Fetched cart")
  const data = response.data === null ? [] : response.data.getMyShoppingCart;
  return data;
});

const cartAsync = createSlice({
  name: 'cart',
  initialState: { items: [], pagination: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addToCartAsync.fulfilled, (state, action) => {
        // Update state after adding items to the cart
        state.items.push(action.payload);
      })
      .addCase(removeItemsAsync.fulfilled, (state, action) => {
        // Remove items from the cart based on itemIds
        state.items = state?.items?.filter((item) => !action?.payload?.includes(item._id));
      })
      .addCase(fetchCartAsync.fulfilled, (state, action) => {
        // Update cart with fetched data from the server
        state.items = action.payload.items;
        state.pagination = action.payload.pagination;
      });
  },
});

export const selectCartItems = (state) => state.cart.items;
export const selectCartPagination = (state) => state.cart.pagination;

export default cartAsync.reducer;
