import { gql } from "@apollo/client";


export const GET_CITY = gql`
  query GetCity($state:String!) {
    fetchCities(
      query: { state: $state }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        name
  
      }
      pagination
    }
  }
`;
export const GET_STATES = gql`
  query GetStates($country:String!) {
    fetchStates(
      query: { country: $country }
      pageOptions: { page: 1, limit: 100 }
    ) {
      items {
        _id
        name
        abbreviation
        country {
          _id
          name
        }
      }
      pagination
    }
  }
`;
export const GET_SINGLE_PRODUCT = gql`
  query GetSingleProduct($_id: String!) {
    listAllAvailableProductsWithoutAuthentication(
      query: { _id: $_id }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        price
        createdAt
        updatedAt
        categories {
          name
        }
        country {
          flagUrl
          name
        }

        currency {
          name
          symbol
          shortCode
        }
        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
    }
  }
`;
export const GET_SINGLE_PRODUCT_DESCRIPTION = gql`
  query GetSingleProduct($_id: String!) {
    listAllAvailableProductsWithoutAuthentication(
      query: { _id: $_id }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        price
        description
        rating

        merchant {
          firstName
          lastName
          phone
        }

        isInStock
        isNegotiable
        quantityInStock
        images
        status
      }
    }
  }
`;

export const GET_TOPSELLERS_PRODUCTS = gql`
  query {
    getMarketPlaceBestSellingProductListingForUnAuthenticatedUser {
      _id
      name
      price
      rating
      country {
        flagUrl
        name
      }
      merchant {
        firstName
        lastName
        phone
      }
      images
      isInStock
      isNegotiable
      quantityInStock
      images
      status
    }
  }
`;
export const GET_TOPSELLERS_PRODUCTS_AUTH = gql`
  query {
    getMarketPlaceBestSellingProductListing {
      _id
      reference
      name
      description
      alias
      price
      merchant {
        firstName
        lastName
        phone
      }
      currency {
        name
        symbol
        shortCode
      }
      isInStock
      isNegotiable
      quantityInStock
      images
      status
    }
  }
`;

export const GET_PROFILE = gql`
  query {
    getProfile
  }
`;

export const GET_COUNTRIES = gql`
  query {
    getAllCountries(
      query: { name: "United" }
      pageOptions: { page: 1, limit: 15 }
    ) {
      items {
        _id
        name
        flagUrl
        dialingCode
      }
    }
  }
`;

export const GET_ALL_TRANSACTIONS = gql`
  query {
    fetchDefaultWalletHistories(
      query: {  }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        amount
        gateway
        description
        action
        type
        status
        paidAt
        updatedAt
        currency {
          symbol
          shortCode
          name
          alias
        }
      }
      pagination
    }
  }
`;

export const GET_ALL_ADDRESS = gql`
  query {
    fetchAllAddresses {
      firstName
      lastName
      addressOne
      addressTwo
      zipCode
      phone
      isDefault
      createdAt
      updatedAt
      city {
        _id
        name
      }
      state {
        _id
        name
      }
      country {
        _id
        name
      }
    }
  }
`;

export const GET_ALL_PUSRSES = gql`
  query {
    getWallets {
      _id
      spendingBalance
      earningBalance
      isActive
      isDefault
      currency {
        symbol
        shortCode
      }
    }
  }
`;

export const GET_SERVICES = gql`
  query {
    listAllAvailableServicesWithoutAuthentication(
      filter: {}
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        title
        description
        price
        rateType
        status
        isNegotiable
        createdAt
        images
        updatedAt
        city {
          name
          state {
            name
            country {
              name
              currency {
                name
                symbol
                alias
              }
            }
          }
        }
      }
      pagination
    }
  }
`;
export const GET_SINGLE_SERVICE = gql`
  query GetSingleService($_id: String!) {
    listAllAvailableServicesWithoutAuthentication(
      filter: { _id: $_id }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        title
        description
        price
        rateType
        status
        isNegotiable
        createdAt
        images
        updatedAt
        city {
          name
          state {
            name
            country {
              name
              flagUrl
              currency {
                name
                symbol
                alias
              }
            }
          }
        }
      }
      pagination
    }
  }
`;

export const GET_CART = gql`
  query {
    getMyShoppingCart(pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        quantity
        pricePerUnit
        totalAmount
        createdAt
        updatedAt
        product {
          _id
          name
          description
          images
        }
        session {
          _id
          currency {
            name
            shortCode
            shortCode
          }
        }
      }
      pagination
    }
  }
`;

export const GET_BUYER_ORDERS = gql`
  query {
    getMyOrders(query: {}, pageOptions: { page: 1, limit: 25 }) {
      items {
        _id
        reference
        pricePerUnit
        amountPaid
        quantity
        status
        createdAt
        updatedAt
        order {
          currency {
            name
            alias
            shortCode
            symbol
          }
        }
        statusHistory {
          _id
          status
          createdAt
          updatedAt
        }
        product {
          _id
          name
          images
        }
      }
      pagination
    }
  }
`;

export const GENERAL_SEARCH = gql`
  query generalSearch($query:String!) {
    searchInput(payload: { query: $query }) {
      source
      id
      result
    }
  }
`;

export const GET_SINGLE_ORDER = gql`
  query singleOrder($reference : String!) {
    getMyOrders(
      query: { reference: $reference }
      pageOptions: { page: 1, limit: 25 }
    ) {
      items {
        _id
        reference
        pricePerUnit
        amountPaid
        quantity
        status
        createdAt
        updatedAt
        order {
          currency {
            name
            alias
            shortCode
            symbol
          }
        }
        statusHistory {
          _id
          status
          createdAt
          updatedAt
        }
        product {
          _id
          name
          images
        }
      }
      pagination
    }
  }
`;