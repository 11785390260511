import { Box, Typography, Switch, Divider } from '@mui/material'
import React from 'react'

const Notification = () => {
   const [checked, setChecked] = React.useState(true);

     const handleChange = (event) => {
       setChecked(event.target.checked);
     };
  return (
    <>
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box>
          <Typography sx={{ color: "#333", fontWeight: 500, fontSize: "16px" }}>
            Notifcation
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Box>
        <Box sx={{ mt: 3, p: 2 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
            >
              Special Offers
            </Typography>
            <Switch       checked={checked}
      onChange={handleChange} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
            >
              General Notification
            </Typography>
            <Switch checked={checked} onChange={handleChange} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
            >
              Promo & Discount
            </Typography>
            <Switch       checked={checked}
      onChange={handleChange} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
            >
              New Service Available
            </Typography>
            <Switch       checked={checked}
      onChange={handleChange} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 400 }}
            >
              App Updates
            </Typography>
            <Switch       checked={checked}
      onChange={handleChange} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Notification