import { Box, Typography, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ServiceCard from '../Cards/ServiceCard'
import { Link } from 'react-router-dom'
import { getServices } from '../../store/serviceSlice'
import StatusCode from '../../Utils/StatusCode'
import Loader from '../Common/Loader'


const ServiceListings = () => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(getServices())
  }, [])
      const { data, status } = useSelector((state) => state.service);
  return (
    <>
      {status === StatusCode.LOADING && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2, pb: 3, my: 5 }}>
        <Typography variant="subtitle2" sx={{fontSize:{lg:'24px', xs:'20px', md:'24px', sm:'20px'}}}>Service Listings</Typography>

        <Grid container spacing={2} sx={{ my: 1 }}>
          {data === undefined ? (
            <p>No Data</p>
          ) : (
            data?.slice(0, 10).map((service, index) => (
              <Grid item lg={2.4} md={3} sm={4} xs={6} key={service?._id}>
                <Link to={`/service-view/${service._id}`} key={index}>
                  <ServiceCard
                    image={service.images}
                    title={service.title}
                    price={service.price.toLocaleString()}
                    ratings={service.ratings}
                  />
                </Link>
              </Grid>
            ))
          )}
        </Grid>
      </Box>
    </>
  );
}

export default ServiceListings
