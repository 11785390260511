import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Button,
  Divider,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import successIcon from "../../assets/Icons/successIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import Timeout from "./Timeout";
import { RESEND_OTP, VALIDATE_OTP } from "../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import { setUserID } from "../../store/userSlice";
import LoadButton from "../../Components/Common/LoadButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  pt: 6,
};

const Verify = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [otpValue, setOtpValue] = React.useState("");
  const otpDuration = 60; // Duration of the OTP timer in seconds
  const [otpValidate, setOtpValidate] = useState(true);
  const user = useSelector((state) => state.user.email);
  const userID = useSelector((state) => state.user.uuid);
  const dispatch = useDispatch();
  // console.log(userID.uuid)
  const [resendOtpMutation, { loading: resendLoading, error: resendError }] =
    useMutation(RESEND_OTP);
  const [validateOtpMutation, { loading, error }] = useMutation(VALIDATE_OTP);

  const handleOtpTimeout = () => {
    // Handle OTP timeout, e.g., show a message
    console.log("OTP has expired.");
    setOtpValidate(false);
  };
  const ResendOtp = async () => {
    try {
      await resendOtpMutation({
        variables: { email: user },
      });
      setOtpValidate(true);
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  const handleValidateOtp = async () => {
    try {
      const uuid = userID;
      const otp = otpValue;

      const { data } = await validateOtpMutation({
        variables: { uuid, otp },
      });

      // Handle success, e.g., show a success message
      console.log(data.validateOTP);
      dispatch(setUserID(data.validateOTP.userId));
      setOpen(true);
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error(error);
    }
  };
  return (
    <>
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Verify Email Address
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 200, fontSize: "14px" }}>
            Enter the code, we sent to {user && user}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                // fontFamily: "Gordita",
              }}
            >
              Enter OTP
            </InputLabel>
            <TextField
              valuee={otpValue}
              onChange={(e) => setOtpValue(e.target.value)}
              size="large"
              fullWidth
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            {otpValidate ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Don't receive a code? Resend in
                </Typography>
                <Timeout duration={otpDuration} onTimeout={handleOtpTimeout} />
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    fontSize: "14px",
                    color: "#666",
                  }}
                >
                  Don't receive a code?
                </Typography>
                <Typography
                  onClick={ResendOtp}
                  sx={{
                    textAlign: "center",
                    mt: 1,
                    ml: 1,
                    fontWeight: 500,
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#148b77",
                  }}
                >
                  Resend
                </Typography>
              </Box>
            )}
            {error && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,
                  fontFamily: "inter",
                  fontSize: "12px",
                }}
              >
                {error.message ===
                "Cannot read properties of null (reading 'toString')"
                  ? "Wrong OTP details"
                  : error.message}
              </Typography>
            )}
       
            <LoadButton
              text="Verify"
              text2="verifying"
              action={handleValidateOtp}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} align="center">
          <img src={successIcon} alt="successful" width={100} />

          <Typography
            sx={{ fontWeight: 600, color: "#1a1a1a", fontSize: "20px", mt: 4 }}
          >
            Email Verified
          </Typography>
          <Typography
            sx={{ fontWeight: 200, color: "#1a1a1a", fontSize: "14px", mt: 1 }}
          >
            You successfully registered.
          </Typography>
          <Typography
            sx={{ fontWeight: 200, color: "#1a1a1a", fontSize: "13px", mt: -1 }}
          >
            Proceed to create transaction PIN
          </Typography>
          <Link to="/createpin">
            <Button
              variant="contained"
              fullWidth
              sx={{ py: 1, mt: 3, borderRadius: "50px" }}
            >
              Proceed
            </Button>
          </Link>
        </Box>
      </Modal>
    </>
  );
};

export default Verify;
