import React, { useEffect } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import sucessIcon from "../../../assets/Icons/Icon Box.svg";
import { Link } from "react-router-dom";

const style = {
  //   width: "100%",
  border: "none",
  bgcolor: "#ECF8F0",
  p: 1,
  px: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "2px solid #3FB763",
};

const CartAlert = ({open, handleClose}) => {
//   const [open, setOpen] = React.useState(true);
//   const handleOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);
//   useEffect(() => {
//     if (open) {
//       setTimeout(() => {
//         setOpen(false);
//       }, 3000);
//     }
//   }, [open]);
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: 3 }}>
            <img src={sucessIcon} />
            <Typography sx={{ color: "#1a1a1a", fontWeight: 400 }}>
              Item was successfully added to your cart.
            </Typography>
          </Box>
          <Link to="/cart">
            <Button sx={{ textDecoration: "underline" }}>View cart</Button>
          </Link>
        </Box>
      </Modal>
    </>
  );
};

export default CartAlert;
