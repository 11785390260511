import {
  AppBar,
  Box,
  Grid,
  Typography,

} from "@mui/material";
import React, { useState } from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";




const FooterNavBar = () => {
  const [loggedin, setLoggedIn] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <AppBar sx={{ bgcolor: "#fff",  }}>
        <Box sx={{ bgcolor: "#F8F7F7", py: 1, margin: "0 auto", width: "100%" }}>
          <Box
            sx={{
              width: "90%",
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div className="auto-moving-text-container">
              <div className="auto-moving-text">
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontWeight: 400,
                    color: "#1a1a1a",
                    fontSize: "12px",
                    display: { lg: "block", md: "block", xs: "none" },
                  }}
                >
                  Shop and sell on the go, Get it now!!{" "}
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "#148B77" }}
                  >
                    Download Mamaket App
                  </Link>
                </Typography>
              </div>
              <div className="auto-moving-text">
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontWeight: 400,
                    color: "#1a1a1a",
                    fontSize: "12px",
                    display: { lg: "block", md: "block", xs: "none" },
                  }}
                >
                  Shop and sell on the go, Get it now!!{" "}
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "#148B77" }}
                  >
                    Download Mamaket App
                  </Link>
                </Typography>
              </div>
              <div className="auto-moving-text">
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontWeight: 400,
                    color: "#1a1a1a",
                    fontSize: "12px",
                    display: { lg: "block", xs: "none" },
                  }}
                >
                  Shop and sell on the go, Get it now!!{" "}
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "#148B77" }}
                  >
                    Download Mamaket App
                  </Link>
                </Typography>
              </div>
            </div>
          </Box>
        </Box>
        <Grid container sx={{ py: 1, margin: "0 auto", width: "80%" }}>
          <Grid
            item
            lg={11}
            md={7}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Logo variant="text" width="120" />
          </Grid>
          <Grid
            item
            lg={1}
            md={5}
            align="right"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box align="right">
              <Typography sx={{ color: "#1a1a1a", fontSize: "12px" }}>
                English
              </Typography>
            </Box>
     
          </Grid>
        </Grid>
      </AppBar>


    </>
  );
};

export default FooterNavBar;
