import React from "react";
import Navbar from "../../Components/Common/Navbar";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import thumbnail from "../../assets/Products/Thumbnail Images.png";
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Footer from "../../Components/Common/Footer";
import Newsletter from "../../Components/Common/Newsletter";
import { useSelector, useDispatch } from "react-redux";
import emptyFav from '../../assets/Icons/emptyFav.svg'
import { add } from "../../store/cartSlice";
import { removeFav } from "../../store/favouriteSlice";
import { useNavigate } from "react-router-dom";

const Wishlist = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const favProducts = useSelector((state) => state.favourite)
  
  const handleAddCart = (product) => {
    dispatch(add(product))
  }
    const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";
  
  const handleToLogin = () => {
    navigate('/login')
  }

  const handleRemove = (id) => {
    dispatch(removeFav(id))
  }
  return (
    <>
      <Navbar />
      <Box sx={{ pt: 15, margin: "0 auto", width: "85%", mb: 4 }}>
        <Typography sx={{ fontSize: "36px", fontWeight: 600, color: "#333" }}>
          Wishlist
        </Typography>
        <Box sx={{ bgcolor: "#fff", p: 3, my: 3 }}>
          {favProducts.length === 0 ? (
            <>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 4,
                  mt: 3,
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
              >
                <img src={emptyFav} />
              </Box>
            </>
          ) : (
                        <Table>
                <TableHead>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      fontFamily: "inter",
                      fontWeight: 500,
                      color: "#333",
                    }}
                  >
                    Product
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: "15px",
                      fontFamily: "inter",
                      fontWeight: 500,
                      color: "#333",
                    }}
                    align="right"
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "15px",
                      fontFamily: "inter",
                      fontWeight: 500,
                      color: "#333",
                    }}
                    align="right"
                  ></TableCell>
                </TableHead>
                {

                
                  favProducts.map((product) => (
    
                    <TableBody>
             
                      <TableRow>
                        <TableCell>
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Avatar
                              variant="square"
                              src={product?.images[0]}
                              sx={{ width: "70px", height: "70px" }}
                            />
                            <Box>
                              <Typography
                                sx={{
                                  color: "#121212",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {product?.name}
                              </Typography>
                              <Button onClick={() => handleRemove(product?._id)} sx={{ color: "#FF4C5E" }}>
                                <img
                                  src={trash}
                                  alt="trash_icon"
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                Remove
                              </Button>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell align="right">
                          <Typography sx={{ color: "#333", fontSize: "16px" }}>
                            {product?.price}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            onClick={isAuthenticated ? () => handleAddCart(product) : handleToLogin}
                            sx={{
                              color: "#333",
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            Add to Cart
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                }
              </Table>
          )}
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Wishlist;
