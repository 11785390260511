import React from 'react'
import { Box, Avatar, Typography, Button } from '@mui/material'
import blogImg from '../../assets/blog.png'
import { Link } from 'react-router-dom'

const BlogCard = ({image, title, caption}) => {
  return (
<>
<Box sx={{bgcolor:'#fff', p:1}}>
    <Avatar variant='square' sx={{width:'100%', height:'250px'}} src={image}/>
    <Box sx={{mt:1}}>
        <Typography         variant="caption"
              sx={{ fontFamily: "16px", fontWeight: 500 }}>{title}</Typography>
              <br/>
        <Typography         variant="caption"
              sx={{ fontFamily: "12px", fontWeight: 200 }}>{caption}</Typography>
              <Link to="/">
<Button sx={{color:'#1a1a1a', textDecoration:'underline'}}>Read more</Button>
</Link>
    </Box>
</Box>
</>
  )
}

export default BlogCard
