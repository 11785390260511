import { Box } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo1 from '../../assets/Logo/logoNew.svg'
import Logo2 from '../../assets/Logo/logoIcon.svg'
import Logo3 from '../../assets/Logo/logoWhite.svg'
import authLogo from '../../assets/Logo/auth-logo.svg'
import { useSelector } from 'react-redux'

const Logo = ({ width, variant }) => {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
  return (
    <Box>
      <Link to="/">
        {isAuthenticated ? (
          <img src={authLogo} alt="Mamaket_Logo" width={width} />
        ) : (
          <>
            {variant === "white" && (
              <img src={Logo3} alt="Mamaket_Logo" width={width} />
            )}
            {variant === "colored" && (
              <img src={Logo2} alt="Mamaket_Logo" width={width} />
            )}
            {variant === "text" && (
              <img src={Logo1} alt="Mamaket_Logo" width={width} />
            )}
          </>
        )}
      </Link>
    </Box>
  );
}

export default Logo
