import React from 'react'
import '../../../STYLES/main.css'
import { Box, Button, Typography } from '@mui/material'
import logo from '../../../assets/Logo/Logo.svg'
import { Link } from 'react-router-dom'


const Confirmation = () => {
    return (
      <Box className="confirmation_page">
        <Box className="confirm_card">
          <Box
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "85%",
            }}
          >
            <Box>
              <img src={logo} width={100} alt="logo" />
              <Typography
                sx={{
                  mt: 5,
                  fontWeight: 600,
                  fontSize: "50px",
                  lineHeight: "55px",
                  color: "#000",
                }}
              >
                Payment Successful
              </Typography>

              <Typography
                sx={{ mt: 4, fontWeight: 400, color: "#000", fontSize: "14px" }}
              >
                Your mamapurse has been credited successfully
              </Typography>
            </Box>
            <Box sx={{}}>
              <Link to="/account/mamapurse">
                <Button
                  sx={{ bgcolor: "#000", color: "#fff", borderRadius: "0%" }}
                >
                  Back to Mamapurse
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    );
}

export default Confirmation
