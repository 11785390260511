import { Avatar, Box, IconButton, Typography, Rating } from "@mui/material";
import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import img1 from "../../assets/Products/Web Images.png";
import japan from "../../assets/Japan.svg";

const ServiceCard = ({ image, title, price, location, ratings }) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid #F8F7F7",
          bgcolor: "#fff",
          borderRadius: "3px",
          cursor: "pointer",
          transition: "0.3s all linear",
          p: 1,
          "&:hover": {
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          },
        }}
      >
        <Box>
          <Avatar
            variant="square"
            src={image}
            sx={{ width: "100%", height: {xs:"160px", sm:'200px', md:'220px', lg:'240px'} }}
          />
          <Box align="right" sx={{ mt: -6 }}>
            <IconButton sx={{ bgcolor: "#ffffff1a", mr: 2 }}>
              <img src={japan} alt="country_flag" />
            </IconButton>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="caption"
              sx={{ fontFamily: "14px", fontWeight: 500, color:'#333' }}
            >
              {title}
            </Typography>
            <br />
            <Typography
              variant="caption"
              sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
            >
              &#36;{price}
            </Typography>
            <Box>
              <Rating
                name="read-only"
                value={ratings}
                readOnly
                size="small"
                precision={0.5}
              />
            </Box>
            <Typography
              variant="body1"
              sx={{ fontSize: "10px", color: "#1a1a1a" }}
            >
              {location}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ServiceCard;
