import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import email from "../../assets/Icons/email 1.svg";
import phone from "../../assets/Icons/phone 1.svg";

const Newsletter = () => {
  return (
    <>
      <Box sx={{ bgcolor: "#148B77", py: 1 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "95%", xs: "95%" },
          }}
        >
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            rowGap={2}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={6}
              sx={{ display: "flex", justifyContent:{xs:'space-between', sm:'space-between', md:'initial', lg:'initial'} }}
              xs={12}
            >
              <Box sx={{ display: "flex", alignItems: "center",  }}>
                <img src={email} alt="email" />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      mb: -1,
                      fontSize: { lg: "15px", xs: "12px" },
                    }}
                  >
                    Email Support
                  </Typography>
                  <a
                    href="mailto:care@mamaket.com"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 200,
                        fontSize: { lg: "15px", xs: "12px" },
                      }}
                    >
                      care@mamaket.com
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ml: { lg: 5, xs: 5 },
                }}
              >
                <img src={phone} alt="phone" />
                <Box sx={{ ml: 2 }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      mb: -1,
                      fontSize: { lg: "15px", xs: "12px" },
                    }}
                  >
                    Phone Support
                  </Typography>
                  <a
                    href="tel:+1 (705) 12 3456"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontWeight: 200,
                        fontSize: { lg: "15px", xs: "12px" },
                      }}
                    >
                      +1 (705) 12 3456
                    </Typography>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              xs={12}
              md={6}
              sm={6}
              align="right"
              // sx={{ border: "1px  solid red" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {lg:"right", md:'right', sm:'center', xs:'center'},
                  alignItems: "center",
                }}
              >
                <TextField
                  size="small"
                  placeholder="Email Address"
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",

                      fontSize: "13px",
                      borderRadius: "5px 0px 0px 5px",
                      offset: " 1px solid #737373",
                      backgroundColor: "#fff",
                      width: "270px",
                      height: "40px",
                      // Replace with your desired font family
                    },
                  }}
                />
                <Button
                  sx={{
                    color: "#fff",
                    bgcolor: "#1A1A1A",
                    borderRadius: "0px 3px 3px 0px",
                    height: "40.9px",
                  }}
                >
                  Subscribe
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Newsletter;
