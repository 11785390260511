import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import { Box } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import BreadCrumbs from "../../Components/Common/BreadCrumbs";
import img1 from "../../assets/Products/1.png";
import "../../STYLES/main.css";

import SimilarProducts from "../../Components/Products/ProductsView/SimilarProducts";
import Newsletter from "../../Components/Common/Newsletter";
import Footer from "../../Components/Common/Footer";
import Overview from "../../Components/Services/ServiceView/Overview";
import Description from "../../Components/Services/ServiceView/Description";

const ServiceView = () => {
  const { id } = useParams();

  return (
    <>
      <Navbar />
      <Box>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
            mt: { lg: 15, md: 15, sm: 8, xs: 9 },
          }}
        >
          {/* <BreadCrumbs /> */}

          <Overview serviceID={id} />
          <Description serviceID={id} />
          <SimilarProducts />
        </Box>
      </Box>

      <Newsletter />
      <Footer />
    </>
  );
};

export default ServiceView;
