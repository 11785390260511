import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Button,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import { Link, useNavigate } from "react-router-dom";
import googleImg from "../../assets/Icons/googs.svg";
import facebook from "../../assets/Icons/Facebook.svg";
import apple from "../../assets/Icons/bi_apple.svg";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setUserEmail, setUserId } from '../../store/userSlice'
import { LOGIN_MUTATION, RESEND_OTP, SIGNIN_SOCIAL } from "../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Loader from "../../Components/Common/Loader";
import './login.css'
import { useGoogleLogin } from "@react-oauth/google";
// import { GoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "react-google-login";



const Login = () => {

    function handleCallbackResponse(response) {
      console.log(response.credential);
      // var userObject = jwtDecode(response.credential);
      // console.log(userObject);
    }
    useEffect(() => {
      /* global google */
      google.accounts.id.initialize({
        client_id:
          "348947650065-a7m9ok6ki5r2aplrrlksdjk4k9foaf6f.apps.googleusercontent.com",
        callback: handleCallbackResponse,
      });
      google.accounts.id.renderButton(document.getElementById("signInDiv"), {
        theme: "outline",
        size: "large",
        border:"none"
      });
    }, []);
  
  const navigate = useNavigate()
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = React.useState(false);
  const [accessToken, setAccessToken] = useState(null)
  const dispatch = useDispatch();
  const [loginMutation, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [loginSocial, {loading:socialLoader, error:socialError}] = useMutation(SIGNIN_SOCIAL)
   const [resendOtpMutation, { loading: resendLoading, error: resendError }] =
     useMutation(RESEND_OTP);
  
const loginGoogle = useGoogleLogin({
  onSuccess: (tokenResponse) =>{ console.log(tokenResponse)
  setAccessToken(tokenResponse?.access_token);
  }
  
});
const loginGoogle2 = useGoogleLogin({
  onSuccess: (responseMessage) => console.log(responseMessage),
  flow: "auth-code",
});

  const handleLogin = async () => {
         try {
      const { data } = await loginMutation({
        variables: { email, password }
      });


           console.log(data.loginUser);
           if (data?.loginUser?.message === "Login succeeded") {
             dispatch(setUser(data?.loginUser));
     localStorage.setItem("isAuthenticated", "true");
     localStorage.setItem("mamaketUserToken", data?.loginUser?.accessToken);
     localStorage.setItem("tokenExpiresIn", data?.loginUser?.tokenExpiresIn);
             navigate('/')
           }
           else if (data?.loginUser.message === "VERIFY_OTP") {
             navigate('/verify')
             dispatch(setUserEmail(email))
             ResendOtp()
           }
           else if (data?.loginUser.message === "CREATE_TRANSACTION_PIN") {
                  dispatch(setUserEmail(email));
             navigate('/createpin')
           }
    } catch (error) {
      // Handle error, e.g., show an error message
      console.error(error);
    }

     
    };
  
        const handleKeyPress = (event) => {
          if (event.key === "Enter") {
            // Call your desired function here
            handleLogin()
          }
        };
    const ResendOtp = async () => {
      try {
    const {data} =   await resendOtpMutation({
          variables: { email: email },
    });
        console.log(data)
dispatch(setUserId(data?.resendOTP?.uuid));
      } catch (error) {
        console.error("Error resending OTP:", error);
      }
    };
  
  const handleSuccess = (googleData) => {
    console.log(googleData)
  }
  const handleFailure = (result) => {
alert(result )
  }

  // const handleCustomGoogleLoginClick = () => {
  //   // Trigger Google OAuth flow when the custom button is clicked
  //   const googleAuth = window.gapi.auth2.getAuthInstance();
  //   googleAuth.signIn().then((googleUser) => {
  //     const id_token = googleUser.getAuthResponse().id_token;
  //     // Handle the Google OAuth response here (id_token)
  //     console.log(id_token);
  //   });
  // };
  return (
    <>
      <Navbar />
      {resendLoading && <Loader />}
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        <Box
          sx={{
            margin: "0 auto",
            width: { lg: "30%", xs: "80%" },
            bgcolor: "#fff",
            border: "1px solid #E6E6E6",
            borderRadius: "5px",
            px: 5,
            py: 7,
            mb: 4,
          }}
        >
          <Typography
            sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
          >
            Welcome back
          </Typography>
          <Typography sx={{ color: "#666", fontWeight: 200, fontSize: "14px" }}>
            Please enter your detail
          </Typography>

          <Box sx={{ mt: 3 }}>
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                // fontFamily: "Gordita",
              }}
            >
              Email
            </InputLabel>
            <TextField
              onKeyPress={handleKeyPress}
              placeholder="Email Address"
              size="large"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <InputLabel
              sx={{
                color: "#2B2B2B",
                fontSize: "13px",
                fontWeight: 600,
                // fontFamily: "Gordita",
              }}
            >
              Password
            </InputLabel>
            <TextField
              value={password}
              onKeyPress={handleKeyPress}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"}
              size="large"
              fullWidth
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                      ) : (
                        <VisibilityOffOutlinedIcon sx={{ fontSize: "16px" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox />{" "}
                <Typography sx={{ fontSize: "14px" }}>Remember me</Typography>
              </Box>
              <Link
                to="/forget_password"
                style={{
                  textDecoration: "none",
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Forgot password?
              </Link>
            </Box>

            {error && (
              <Typography
                sx={{
                  color: "red",
                  textAlign: "center",
                  bgcolor: "#ff00001a",
                  border: "0.5px solid red",
                  borderRadius: 1,
                  fontFamily: "inter",
                  fontSize: "12px",
                }}
              >
                {error.message}
              </Typography>
            )}
            {!loading && (
              <Button
                variant="contained"
                fullWidth
                onClick={handleLogin}
                sx={{ borderRadius: "20px", py: 1, mt: 3 }}
              >
                Sign In
              </Button>
            )}

            {loading && (
              <>
                <LoadingButton
                  fullWidth
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled
                  sx={{ borderRadius: "20px", py: 1, mt: 3 }}
                >
                  Signing In
                </LoadingButton>
              </>
            )}
            <Typography
              sx={{
                textAlign: "center",
                mt: 1,
                fontSize: "14px",
                color: "#666",
              }}
            >
              New to Mamaket?{" "}
              <Link
                to="/register"
                style={{
                  textDecoration: "none",
                  fontFamily: "inter",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#148B77",
                }}
              >
                Sign up
              </Link>
            </Typography>

            <Divider sx={{ mt: 3 }}>
              <Typography>OR</Typography>
            </Divider>
          </Box>

          <Box align="center" sx={{ mt: 3 }}>
            <Box id="signInDiv"></Box>
            <Button
       
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={googleImg} style={{ marginRight: "10px" }} />
              Sign in with Google
            </Button>
            <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={apple} style={{ marginRight: "10px" }} />
              Sign in with Apple ID
            </Button>
            <Button
              sx={{
                bgcolor: "#F8F7F7",
                color: "#1a1a1a",
                fontFamily: "inter",
                fontWeight: 400,
                mt: 3,
                fontSize: "14px",
                py: 2,
                borderRadius: "50px",
              }}
              fullWidth
            >
              {" "}
              <img src={facebook} style={{ marginRight: "10px" }} />
              Sign in with Facebook
            </Button>
            ;
          </Box>
        </Box>
      </Box>
      <Newsletter />
      <Footer />
    </>
  );
};

export default Login;
