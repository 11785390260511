import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import card_vector from "../../assets/mamapurse_vector.svg";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useLazyQuery } from "@apollo/client";
import history_icon from "../../assets/Icons/empty_history.svg"
import Cashout from "../Components/Mamapurse/Cashout";
import AddCash from "../Components/Mamapurse/AddCash";
import { useDispatch, useSelector } from "react-redux";
import StatusCode from "../../Utils/StatusCode";
import Loader from "../../Components/Common/Loader";
import { GET_ALL_PUSRSES, GET_ALL_TRANSACTIONS } from "../../Utils/Queries";
import arrowUp from '../../assets/Icons/Arrow - Up Square.svg'
import arrowDown from '../../assets/Icons/Arrow - Down Square.svg'



const Mamapurse = () => {


  const [getWallets, { data: wallets, loading, error }] = useLazyQuery(GET_ALL_PUSRSES)
  const [getTransactions, { data: transactions, loading:  transactionLoad }] = useLazyQuery(GET_ALL_TRANSACTIONS)
  
  useEffect(() => {
    getWallets()
    getTransactions()
    
},[])

  const theme = useTheme();
  const spendingBalance = wallets?.getWallets[0]?.spendingBalance
  const transactiionList = transactions?.fetchDefaultWalletHistories?.items
  console.log(transactiionList, "spendin");
  const [hidden, setHidden] = useState(false)

  function formatDateString(originalDateString) {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Date(originalDateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
  }



  return (
    <>
      {loading && <Loader />}

      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Typography sx={{ color: "#333", fontSize: "20px", fontWeight: 500 }}>
          Mamapurse
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Grid container sx={{ display: "flex", columnGap: 2 }}>
            <Grid item lg={4.5} md={4.2} sm={12} xs={12}>
              <Box
                sx={{
                  // display: "grid",
                  // placeItems: "center",
                  // height: "300px",
                }}
              >
                <Box
                  sx={{
                    bgcolor: theme.palette.primary.main,
                    height: "180px",
                    borderRadius: 0.3,
                    width: "100%",
                    backgroundImage: `url('${card_vector}')`,
                    p: 2,
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
                  >
                    <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                      Total Balance
                    </Typography>
                    <IconButton onClick={() => setHidden(!hidden)}>
                      {hidden ? (
                        <VisibilityOffOutlinedIcon
                          sx={{ color: "#fff", fontSize: "14px" }}
                        />
                      ) : (
                        <VisibilityOutlinedIcon
                          sx={{ color: "#fff", fontSize: "14px" }}
                        />
                      )}
                    </IconButton>
                  </Box>
                  {hidden ? (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "28px",
                        mt: -2,
                        fontWeight: 600,
                      }}
                    >
                      {`$${
                        spendingBalance ? spendingBalance.toLocaleString() : "0"
                      }.00`}
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "28px",
                        mt: -2,
                        fontWeight: 600,
                      }}
                    >
                      {`$***`}
                    </Typography>
                  )}

                  <AddCash text="Add Cash" />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={7.2} md={7.2} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  // height: "300px",
                  pl: {lg:2, md:2, sm:0, xs:0},
                  mt:{lg:0, md:0, sm:3, xs:3},
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "14px", fontWeight: 500 }}
                >
                  Transaction History
                </Typography>
                <Box
                  sx={{
                    bgcolor: "#f8f7f7",
                    height: "180px",
                    mt: 1,
                    p: 2,
                    overflow: "scroll",
                  }}
                >
                  {!transactiionList ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <img src={history_icon} alt="history_empty_icon" />
                        <Typography sx={{ color: "#999", fontSize: "12px" }}>
                          No history available
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <>
                      {transactiionList.map((transaction, index) => (
                        <Box
                          sx={{
                            mt: 0.5,
                            display: "flex",
                            alignItems: "center",
                            borderBottom: "1px solid #f2f2f2",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: 2,
                            }}
                          >
                            <img
                              src={
                                transaction?.type === "Wallet Top Up"
                                  ? arrowDown
                                  : arrowUp
                              }
                              alt="cash_out"
                            />
                            <Box>
                              <Typography
                                sx={{
                                  color: "#212121",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                }}
                              >
                                {transaction?.type === "Wallet Top Up"
                                  ? "Cash Added"
                                  : "     Cash Out"}
                              </Typography>
                              <Typography
                                sx={{ color: "#666", fontSize: "11px" }}
                              >
                                {`${formatDateString(transaction?.updatedAt)}`}{" "}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              color: "#212121",
                              fontWeight: 500,
                              fontSize: "14px",
                            }}
                          >
                          ${transaction?.amount.toLocaleString()}
                          </Typography>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Mamapurse;




