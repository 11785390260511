import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Drawer,
  IconButton,
  Container,
  Button,
  Divider,
  Box,
  Typography,
  ListItem,
  Badge,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import sLogo from "../../assets/Logo/slogo.svg";
import Logo from "../../assets/Logo/logoNew.svg";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import user from "../../assets/Icons/user2.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Grow } from "@mui/material";
import PropTypes from "prop-types";
import box from "../../assets/Icons/box.svg";
import wallet from "../../assets/Icons/Wallet.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ProductDrawer from "./ProductDrawer";
import ServiceDrawer from "./ServiceDrawer";
import CloseIcon from "@mui/icons-material/Close";
import ChatIcon from "@mui/icons-material/Chat";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { logoutUser } from "../../store/userSlice";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Drawers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const favProducts = useSelector((state) => state.favourite);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [openDrawer, setOpenDrawer] = useState(false);
  const cartProducts = useSelector((state) => state.cart);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);

  const handleLogOut = () => {
    navigate("/");
    setAnchorEl(null);
    dispatch(logoutUser());
  };
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: "70vw" }}
      >
        <Box sx={{ p: 2, width: "70vw" }}>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton
              onClick={() => setOpenDrawer(!openDrawer)}
              sx={{ mr: 2 }}
            >
              <CloseIcon sx={{ color: "#000", fontSize: "25px" }} />
            </IconButton>
            <Link to="/">
              <img src={Logo} width={80} style={{ marginTop: "10px" }} />
            </Link>
          </Box>
          <Typography>Categories</Typography>

          <Box sx={{ mt: 3, height: "60vh", overflowY: "auto" }}>
            <Box sx={{ border: 0.2, borderColor: "#3333331a" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Tab
                  label="Products"
                  {...a11yProps(0)}
                  sx={{ fontFamily: "inter", width: "50%" }}
                />
                <Tab
                  label=" Services"
                  {...a11yProps(1)}
                  sx={{ fontFamily: "inter", width: "50%" }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <ProductDrawer />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ServiceDrawer />
            </CustomTabPanel>
          </Box>
          <Box>
            <Link to="/account/mamapurse">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<NotificationsNoneIcon />}
              >
        Notification
              </Button>
            </Link>
        
    
          </Box>
          <Box>
            <Link to="/account/mamapurse">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<img src={wallet} />}
              >
                Mamapurse
              </Button>
            </Link>
            <Link to="/account/orders">
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                sx={{ fontWeight: 500, color: "#333" }}
                startIcon={<img src={box} />}
              >
                My Order
              </Button>
            </Link>
          </Box>
          <Box>
            {isAuthenticated ? (
              <>
                <Button
                  onClick={handleLogOut}
                  sx={{ fontWeight: 500, color: "#333" }}
                  startIcon={<NoAccountsIcon />}
                >
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", gap: 1, pr: 1 }}>
                  <Link to="/login">
                    <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                      Login
                    </Typography>
                  </Link>
                  <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                    /
                  </Typography>
                  <Link to="/register">
                    <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                      Signup
                    </Typography>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Drawer>
      {/* </Popover> */}
      <Box
        sx={{
          py: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon sx={{ color: "#000" }} />
          </IconButton>
          <Link to="/">
            <img src={sLogo} />
          </Link>
        </Box>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {isAuthenticated ? (
            <>
              <IconButton>
                <Link to="/cart">
                  <StyledBadge badgeContent={cartProducts.length} color="error">
                    <ShoppingCartIcon
                      color="action"
                      sx={{ color: "#000", fontSize: "20px" }}
                    />
                  </StyledBadge>
                </Link>
              </IconButton>
              <Link to="/wishlist">
                <StyledBadge badgeContent={favProducts?.length} color="error">
                  <FavoriteIcon
                    color="action"
                    sx={{ color: "#000", fontSize: "20px" }}
                  />
                </StyledBadge>
              </Link>
              <IconButton>
                <Link to="/chat">
                  <StyledBadge badgeContent={0} color="error">
                    <ChatIcon
                      color="action"
                      sx={{ color: "#000", fontSize: "20px" }}
                    />
                  </StyledBadge>
                </Link>
              </IconButton>
              <Link to="/my-account">
                <IconButton sx={{ borderRadius: "0px", mt: 0.8 }}>
                  <img src={user} />
                </IconButton>
              </Link>
            </>
          ) : (
            <>
              <Box sx={{ display: "flex", gap: 1, pr: 1 }}>
                <Link to="/login">
                  <Typography
                    sx={{ fontWeight: 500, color: "#1a1a1a", fontSize: "13px" }}
                  >
                    Login
                  </Typography>
                </Link>
                <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
                  /
                </Typography>
                <Link to="/register">
                  <Typography
                    sx={{ fontWeight: 500, color: "#1a1a1a", fontSize: "13px" }}
                  >
                    Signup
                  </Typography>
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Drawers;
