import React, { useState } from "react";
import Navbar from "../../Components/Common/Navbar";
import Footer from "../../Components/Common/Footer";
import {
  Box,
  Typography,
  TextField,
  InputLabel,
  Checkbox,
  Button,
  Divider,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Newsletter from "../../Components/Common/Newsletter";
import { Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import successIcon from '../../assets/Icons/password.svg'
import { RESEND_OTP , RESET_PASSWORD} from "../../Utils/Mutations";
import { useMutation } from "@apollo/client";
import LoadButton from "../../Components/Common/LoadButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useFormik } from "formik";
import * as Yup from "yup";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
  pt:6
};

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const ForgetPassword = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [otp, setOtp] = useState(null)
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState('')
    const [activeStep, setActiveStep] = useState(0)
      const [showPassword, setShowPassword] = React.useState(false);
      const [uuid, setUuid] = useState(null)

       const { values, errors, handleBlur, handleChange, handleSubmit } =
         useFormik({
           initialValues: {
             password: "",
             confirmPassword: "",
           },
           validationSchema: validationSchema,
           onSubmit: () => {
          handleResetPassword()
           },
         });

      const [
        resendOtpMutation,
        { loading: resendLoading, error: resendError },
      ] = useMutation(RESEND_OTP);

      const [resetPassword, {loading, data, error}] = useMutation(RESET_PASSWORD)

      const handleResetPassword =  async () =>{
        await resetPassword({
          variables:{
            otp:otp,
            uuid:uuid,
            password:values.password
          }
        }).then((res)=>{
console.log(res)
setOpen(true)
        }).catch((err)=>{
console.log(err)
        })
      }

        const ResendOtp = async () => {
          setIsLoading(true)
      
          try {
           const res =  await resendOtpMutation({
              variables: { email: email },
            });
            setUuid(res?.data.resendOTP.uuid)
            console.log(res)
       setIsLoading(false)
       setActiveStep(1)
          } catch (error) {
            setIsLoading(false)
            console.error("Error resending OTP:", error);
          }
        };
  return (
    <>
      <Navbar />
      <Box sx={{ bgcolor: "#F8F7F7", pt: { lg: 21, xs: 15 }, pb: 5 }}>
        {activeStep === 0 && (
          <>
            <Box
              sx={{
                margin: "0 auto",
                width: { lg: "30%", xs: "80%" },
                bgcolor: "#fff",
                border: "1px solid #E6E6E6",
                borderRadius: "5px",
                px: 5,
                py: 7,
                mb: 4,
              }}
            >
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
              >
                Forgot Password
              </Typography>
              <Typography
                sx={{ color: "#666", fontWeight: 200, fontSize: "14px" }}
              >
                Enter your email and we'll send instructions on resetting your
                password.
              </Typography>

              <Box sx={{ mt: 3 }}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    fontWeight: 600,
                    // fontFamily: "Gordita",
                  }}
                >
                  Email Address
                </InputLabel>
                <TextField
                  // onKeyPress={handleKeyPress}
                  value={email}
                  error={resendError}
                  helperText={resendError?.message}
                  onChange={(e) => setEmail(e.target.value)}
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />

                <LoadButton
                  action={ResendOtp}
                  text="submit"
                  isLoading={isLoading}
                  text2="sending OTP to your email"
                />
                <Link to="/">
                  <Typography
                    sx={{
                      textAlign: "center",
                      mt: 1,
                      fontSize: "14px",
                      color: "#666",
                      fontWeight: 400,
                    }}
                  >
                    Back to Log in
                  </Typography>
                </Link>
              </Box>
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Box
              sx={{
                margin: "0 auto",
                width: { lg: "30%", xs: "80%" },
                bgcolor: "#fff",
                border: "1px solid #E6E6E6",
                borderRadius: "5px",
                px: 5,
                py: 7,
                mb: 4,
              }}
            >
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 600, fontSize: "20px" }}
              >
                Forgot Password
              </Typography>
              <Typography
                sx={{ color: "#666", fontWeight: 200, fontSize: "14px" }}
              >
                Enter the otp send to{" "}
                <span style={{ fontWeight: 400 }}>{email}</span> and your new
                password
              </Typography>

              <Box sx={{ mt: 3 }}>
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    fontWeight: 600,
                    // fontFamily: "Gordita",
                  }}
                >
                  OTP
                </InputLabel>
                <TextField
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  size="large"
                  fullWidth
                  type="number"
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    fontWeight: 600,
                    // fontFamily: "Gordita",
                  }}
                >
                  New Password
                </InputLabel>
                <TextField
                  error={errors.password}
                  helperText={errors.password}
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter New Password"
                  type={showPassword ? "text" : "password"}
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              sx={{ fontSize: "16px" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <InputLabel
                  sx={{
                    color: "#2B2B2B",
                    fontSize: "13px",
                    fontWeight: 600,
                    mt:1.5
                    // fontFamily: "Gordita",
                  }}
                >
                  Confirm Password
                </InputLabel>
                <TextField
                  error={errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  id="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter New Password"
                  type={showPassword ? "text" : "password"}
                  size="large"
                  fullWidth
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
                          ) : (
                            <VisibilityOffOutlinedIcon
                              sx={{ fontSize: "16px" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <LoadButton
                  action={handleSubmit}
                  text="Reset Password"
                  text2="resetting your password"
                  isLoading={loading}
                />
                <Link to="/">
                  <Typography
                    sx={{
                      textAlign: "center",
                      mt: 1,
                      fontSize: "14px",
                      color: "#666",
                      fontWeight: 400,
                    }}
                  >
                    Back to Log in
                  </Typography>
                </Link>
                <Box align="center" sx={{ p: 2 }}>
                  <Typography color="error" sx={{ fontWeight: 500 }}>
                    {error?.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Newsletter />
      <Footer />
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} align="center">
          <img src={successIcon} />

          <Typography
            sx={{ fontWeight: 600, color: "#1a1a1a", fontSize: "20px", mt: 4 }}
          >
            Password changed successful
          </Typography>
          <Typography
            sx={{ fontWeight: 200, color: "#1a1a1a", fontSize: "14px", mt: 1 }}
          >
            You successfully recovered your password.
          </Typography>

          <Link to="/login">
            <Button
              variant="contained"
              fullWidth
              sx={{ py: 1, mt: 3, borderRadius: "50px" }}
            >
              Back to login
            </Button>
          </Link>
        </Box>
      </Modal>
    </>
  );
}

export default ForgetPassword

