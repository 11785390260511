// cartSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client from "../Utils/ApolloInstance";
import { GET_CART } from "../Utils/Queries";

// Define the initial state
const initialState = {
  cart: [],
  loading: "idle",
  error: null,
};

// Define the async thunk for fetching cart data
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (endpoint) => {
    const response = await client.query({
query: GET_CART
    })
    return response.data;
  }
);

// Create the cart slice
const cartsSlice = createSlice({
  name: "carts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCart.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchCart.fulfilled, (state, action) => {
        state.loading = "idle";
        state.cart = action.payload;
      })
      .addCase(fetchCart.rejected, (state, action) => {
        state.loading = "idle";
        state.error = action.error.message;
      });
  },
});

export const selectCart = (state) => state.cart.cart;
export const selectLoading = (state) => state.cart.loading;
export const selectError = (state) => state.cart.error;

export default cartsSlice.reducer;
