import {
    Box,
    Button,
    IconButton,
    TextField,
    Typography,
    Popover,
    Grid,
    Avatar,
    createTheme,
    ThemeProvider,
  } from "@mui/material";
  import React from "react";
  import PropTypes from "prop-types";
  import Tabs from "@mui/material/Tabs";
  import Tab from "@mui/material/Tab";
  import MenuIcon from "@mui/icons-material/Menu";
  import SearchIcon from "@mui/icons-material/Search";
  import LocationOnIcon from "@mui/icons-material/LocationOn";
  import productImg from "../../assets/dropdown-products.png";
  import serviceImg from "../../assets/dropdown-services.png";
import { Link } from "react-router-dom";
  
  const tabTheme = createTheme({
    palette: {
      primary: {
        main: "#333",
      },
    },
  });
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


const CategoryMenu = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
      if (value === 0) {
        setImage(serviceImg);
      } else {
        setImage(productImg);
      }
    };
  
    const [image, setImage] = React.useState(productImg);
  
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
  return (
<>
<Box>
            <IconButton
              onClick={handleClick}
              sx={{ borderRadius: "0px", ml: -1 }}
            >
              <MenuIcon sx={{ color: "#1a1a1a" }} />
              <Typography
                variant="caption"
                sx={{ color: "#1a1a1a", ml: 0.5, fontWeight: 300 }}
              >
                Categories
              </Typography>
            </IconButton>
          </Box>

<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 135, left: 150 }}
        sx={{ "& > div": { borderRadius: "0px" } }}
      >
        <Box sx={{ width: "75vw", p: 3 }}>
          <Grid container spacing={3} sx={{ display: "flex" }}>
            <Grid item lg={7.5} md={7.5}>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    // borderBottom: 1,
                    // borderColor: "divider",
                    bgcolor: "#F8F7F7",
                  }}
                >
                  <ThemeProvider theme={tabTheme}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Tab
                        label="Products"
                        {...a11yProps(0)}
                        sx={{
                          width: "50%",
                          fontFamily: "inter",
                          fontWeight: 400,
                          textTransform: "initial",
                        }}
                      />
                      <Tab
                        label="Services"
                        {...a11yProps(1)}
                        sx={{
                          width: "50%",
                          fontFamily: "inter",
                          fontWeight: 400,
                          textTransform: "initial",
                        }}
                      />
                    </Tabs>
                  </ThemeProvider>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Grid container sx={{ mt: 0.5 }} spacing={2}>
                    <Grid item lg={4} md={4}>
                      <Box sx={{ bgcolor: "#F8F7F7", px: 2, py: 3 }}>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Electronic
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb: 2 }}>
                            <Link to="/category-view">
                                                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          </Link>

                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Grocery
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb:2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Clothing
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <Box sx={{ bgcolor: "#F8F7F7", px: 2, py: 3 }}>
                        <Typography
                          variant="h5"
                  
                        >
                        Men Fashion
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                        >
                        Women Fashion
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb:2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                   
                        >
                       Baby & Kids
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4}>
                      <Box sx={{ bgcolor: "#F8F7F7", px: 2, py: 3 }}>
                        <Typography
                          variant="h5"
                     
                        >
                       Sports
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                  
                        >
                      Books
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb:2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                   
                        >
                        More
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1,  }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                <Grid container sx={{ mt: 0.5 , display:'flex'}} spacing={2}>
                    <Grid item lg={4} md={4}>
                      <Box sx={{ bgcolor: "#F8F7F7", px: 2, py: 3 }}>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Electronic
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Grocery
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1, mb:2 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            textTransform: "uppercase",
                            fontFamily: "inter",
                            fontSize: "14px",
                            color: "#1a1a1a",
                          }}
                        >
                          Clothing
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1 }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
              
                    <Grid item lg={8} md={8}>
                      <Box sx={{ bgcolor: "#F8F7F7", px: 2, py: 3 }}>
                   
                        <Typography
                          variant="h5"
                   
                        >
                        More
                        </Typography>
                        <Box sx={{ mt: 1, pl: 1,  }}>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "12px", color: "#1a1a1a" }}
                          >
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </CustomTabPanel>
              </Box>
            </Grid>
            <Grid item lg={4.5} md={4.5} sx={{ overflow: "hidden", display: "flex" }}>
              <Avatar
                variant="square"
                sx={{ width: "100%", height: "100%" }}
                src={image}
              />
            </Grid>
          </Grid>
        </Box>
      </Popover>
</>
  )
}

export default CategoryMenu
