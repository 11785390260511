import * as yup from "yup";
export const RegSchema = yup.object().shape({
  firstName: yup.string().required("field can't be empty"),
  lastName: yup.string().required(" Required"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email Required"),

  // phoner: yup.number().required("Phone Number Required"),
  // country: yup.string().required("field can't be empty"),

  password: yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});

export const PinSchema = yup.object().shape({
  pin: yup
    .string()
    .required("PIN is required")
    .matches(/^\d{4}$/, "PIN must be 4 digits"),
  confirmPin: yup
    .string()
    .required("Confirm PIN is required")
    .oneOf([yup.ref("pin"), null], "PINs must match"),
});