import {
  Avatar,
  Badge,
  Box,
  Chip,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import socket from "../Utils/socket";
import emptyList from '../assets/Icons/emptylist.svg'



const ChatList = () => {
const navigate = useNavigate()
  const [list, setList] = useState(null);
const [loading, setLoading] = useState(false)



  const MY_OFFERS = "myUserList";
  const EXCEPTION = "exception";
  const GETOFFERS = "getMyUserList";

useEffect(()=>{
  setLoading(true)
// if(socket.connected){
socket?.emit(GETOFFERS);
socket?.on(MY_OFFERS, (data)=>{
  // console.log(data)
  setList(data?.otherUsers)
  setLoading(false)
})
socket?.on(EXCEPTION, (err)=>{
  console.log(err, "Exceptions")
  if(err.message === "Invalid Bearer token"){
navigate('/login')
  }
})

// return () =>{
//   socket.off(MY_OFFERS);
//   socket.off(EXCEPTION)
// }

})

  const formatDate = (inputDate) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    };



    // Parse the input date string and format it using toLocaleDateString
    const formattedDate = new Date(inputDate).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  };
  return (
    <>
      <Box sx={{ overflow: "scroll" }}>
        {list && list.length === 0 && (
          <>
            <Box
              sx={{
                height: "50vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // border: "1px solid red",
              }}
            >
              <img src={emptyList} />
            </Box>
          </>
        )}
        <List>
          {!list && (
            <>
              <Box sx={{ py: 2, display: "flex", columnGap: 2 }}>
                <Skeleton variant="circular" width={45} height={45} />
                <Box sx={{ width: "70%" }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem", width: "50%" }}
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2, display: "flex", columnGap: 2 }}>
                <Skeleton variant="circular" width={45} height={45} />
                <Box sx={{ width: "70%" }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem", width: "50%" }}
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2, display: "flex", columnGap: 2 }}>
                <Skeleton variant="circular" width={45} height={45} />
                <Box sx={{ width: "70%" }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem", width: "50%" }}
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2, display: "flex", columnGap: 2 }}>
                <Skeleton variant="circular" width={45} height={45} />
                <Box sx={{ width: "70%" }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem", width: "50%" }}
                  />
                </Box>
              </Box>
              <Box sx={{ py: 2, display: "flex", columnGap: 2 }}>
                <Skeleton variant="circular" width={45} height={45} />
                <Box sx={{ width: "70%" }}>
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "0.5rem", width: "50%" }}
                  />
                </Box>
              </Box>
            </>
          )}
          {list &&
            list.map((item, index) => (
              <Link to={`/chat/${item?.roomChannel}`}>
                <ListItemButton
                  key={index}
                  sx={{
                    borderBottom: "1px solid #F2F2F2",

                    "&.Mui-selected, :hover": {
                      bgcolor: "#E9FCF9",
                      borderLeft: "1px solid #148B77",
                    },
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      lg={2}
                      sx={{ display: "flex", placeItems: "center" }}
                    >
                      <Badge
                        variant="dot"
                        color="primary"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar sx={{ width: "40px", height: "40px" }} />
                      </Badge>
                    </Grid>
                    <Grid item lg={8} sx={{ pl: 2 }}>
                      <Typography
                        key={index}
                        sx={{
                          color: "#000",
                          fontWeight: 400,
                          fontSize: "14px",
                        }}
                      >
                        {item?.otherUserName}
                      </Typography>

                      <Typography
                        sx={{
                          color: "#4d4d4d",
                          fontWeight: 200,
                          fontSize: "12px",
                          mt: -1,
                        }}
                      >
                        {item?.lastMessages.message.message.length > 30
                          ? item?.lastMessages?.message.message.substring(
                              0,
                              30
                            ) + "..."
                          : item?.lastMessages?.message.message}
                      </Typography>
                    </Grid>
                    <Grid item lg={2} align="right">
                      {item?.offers?.slice(0, 1).map((item, index) => (
                        <Typography key={index} sx={{ fontSize: "10px" }}>
                          {formatDate(item?.updatedAt)}
                        </Typography>
                      ))}
                      <Badge
                        color="primary"
                        badgeContent={item.chat}
                        sx={{ mt: -1 }}
                      />
                    </Grid>
                  </Grid>
                </ListItemButton>
              </Link>
            ))}
        </List>
      </Box>
    </>
  );
};

export default ChatList;
