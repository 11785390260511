import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import thumbnail from "../../assets/Products/Thumbnail Images.png";
import trash from "../../assets/Icons/trash.svg";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Newsletter from "../Common/Newsletter";
import Footer from "../Common/Footer";
import { useSelector, useDispatch } from "react-redux";
import emptyCart from "../../assets/Icons/emptyCart.svg";
import {
  remove,
  increaseQuantity,
  decreaseQuantity,
} from "../../store/cartSlice";
import { Link } from "react-router-dom";
import {
  removeItemsAsync,
  addToCartAsync,
  fetchCartAsync,
} from "../../store/cartAsync";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Cart = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const authCart = useSelector((state) => state.cartAsync.items) || [];

  console.log(authCart, "authCart");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const cartProducts = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(fetchCartAsync());
  }, [dispatch]);

  const removeCart = (id) => {
    // dispatch(remove(id));
    handleRemoveItem(id)
  };
  const handleIncreaseQuantity = (id, quantity) => {
    dispatch(increaseQuantity(id));
    handleAddToCart(id, quantity);
  };
  const handleDecreaseQuantity = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const totalSubtotal = authCart.reduce(
    (total, item) => total + item.totalAmount * item.quantity,
    0
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const renderedCart = authCart;
  const handleAddToCart = (id, quantity) => {
    // Example of adding an item to the cart
    const payload = {
      product: id,
      quantity: quantity,
    };
    dispatch(addToCartAsync(payload));
    // console.log(payload.product, "Akin");
  };
  const handleRemoveItem = (id) => {
 console.log(id, "new id")

    dispatch(removeItemsAsync(id));
    // console.log(payload.product, "Akin");
  };
  useEffect(() => {
    // Fetch cart items when the component mounts
    dispatch(fetchCartAsync());
  }, [dispatch]);
  return (
    <>
      <Navbar />
      {isMobile ? (
        <>
          <Box
            sx={{
              pt: 12,
              margin: "0 auto",
              width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
              mb: 2,
              px: 2,
              boxSizing: "border-box",
              bgcolor: "#fff",
            }}
          >
            <Typography
              sx={{ fontSize: "36px", fontWeight: 400, color: "#333" }}
            >
              Cart
            </Typography>
            {renderedCart.length === 0 ? (
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 4,
                  mt: 3,
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
              >
                <img src={emptyCart} />
              </Box>
            ) : (
              <Box sx={{ bgcolor: "#fff", p: 2, mt: 0 }}>
                <Box sx={{}}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label="Product"
                        sx={{ fontFamily: "inter", width: "50%" }}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Service"
                        sx={{ fontFamily: "inter", width: "50%" }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>



                  {/* Cart for Products */}
                  <CustomTabPanel value={value} index={0}>
                    <Box>
                      {renderedCart &&
                        renderedCart.map((item, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              pb: 2,
                              borderBottom: "0.5px solid #e6e6e6",
                              mb: 2,
                            }}
                          >
                            <Box sx={{ display: "flex", columnGap: 2 }}>
                              <Avatar
                                variant="square"
                                src={item?.product?.images[0]}
                                sx={{ width: "70px", height: "80px" }}
                              />
                              <Box>
                                <Typography
                                  sx={{
                                    color: "#121212",
                                    fontSize: "16px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.product?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "14px",
                                    fontWeight: 300,
                                  }}
                                >
                                  &#36;
                                  {(item?.totalAmount).toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                                </Typography>
                                <Box>
                                  <Box
                                    sx={{
                                      border: "0.5px solid #ccc",
                                      borderRadius: "4px",
                                      display: "flex",
                                      width: "80px",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      px: 1,
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleDecreaseQuantity(item._id)
                                      }
                                    >
                                      <RemoveIcon sx={{ fontSize: "10px" }} />
                                    </IconButton>
                                    <Typography
                                      sx={{
                                        fontWeight: 300,
                                        color: "#121212",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item?.quantity}
                                    </Typography>
                                    <IconButton
                                      onClick={() =>
                                        handleIncreaseQuantity(item._id)
                                      }
                                    >
                                      <AddIcon sx={{ fontSize: "10px" }} />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                            <IconButton
                              sx={{
                                color: "#FF4C5E",
                                height: "50px",
                                width: "50px",
                              }}
                              onClick={() => removeCart(item._id)}
                            >
                              <img
                                src={trash}
                                alt="trash_icon"
                                style={{ marginRight: "3px" }}
                              />{" "}
                            </IconButton>
                          </Box>
                        ))}
                      <Box>
                        <Box sx={{ width: "100%", bgcolor: "#fff", mt: 3 }}>
                          <Table>
                            <TableHead>
                              <TableCell align="right"></TableCell>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell>Subtotal</TableCell>
                                <TableCell
                                  align="right"
                                  sx={{
                                    color: "#1a1a1a",
                                    fontWeight: 500,
                                    fontFamily: "inter",
                                  }}
                                >
                                  $
                                  {totalSubtotal.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                          {isAuthenticated ? (
                            <>
                              <Link to="/checkout">
                                <Button
                                  fullWidth
                                  sx={{ mt: 3, borderRadius: "100px" }}
                                  variant="contained"
                                >
                                  Proceed To CheckOut
                                </Button>
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link to="/login">
                                <Button
                                  variant="contained"
                                  fullWidth
                                  size="large"
                                  sx={{ mt: 3, borderRadius: "100px" }}
                                >
                                  Sign in to proceed
                                </Button>
                              </Link>
                              <Typography
                                sx={{ textAlign: "center", fontSize: "12px" }}
                              >
                                New to Mamaket?{" "}
                                <Link to="/register">Sign up </Link>
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </CustomTabPanel>

                  {/* Mobile Service cart */}

                  <CustomTabPanel value={value} index={1}>
                    <Box>
                      {cartProducts.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pb: 2,
                            borderBottom: "0.5px solid #e6e6e6",
                            mb: 2,
                          }}
                        >
                          <Box sx={{ display: "flex", columnGap: 2 }}>
                            <Avatar
                              variant="square"
                              src={item?.images[0]}
                              sx={{ width: "70px", height: "80px" }}
                            />
                            <Box>
                              <Typography
                                sx={{
                                  color: "#121212",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                {item.name}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontSize: "14px",
                                  fontWeight: 300,
                                }}
                              >
                                &#36;
                                {(item.price * item.quantity).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                              <Box>
                                <Box
                                  sx={{
                                    border: "0.5px solid #ccc",
                                    borderRadius: "4px",
                                    display: "flex",
                                    width: "80px",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    px: 1,
                                  }}
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleDecreaseQuantity(item._id)
                                    }
                                  >
                                    <RemoveIcon sx={{ fontSize: "10px" }} />
                                  </IconButton>
                                  <Typography
                                    sx={{
                                      fontWeight: 300,
                                      color: "#121212",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item?.quantity}
                                  </Typography>
                                  <IconButton
                                    onClick={() =>
                                      handleIncreaseQuantity(item._id)
                                    }
                                  >
                                    <AddIcon sx={{ fontSize: "10px" }} />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <IconButton
                            sx={{
                              color: "#FF4C5E",
                              height: "50px",
                              width: "50px",
                            }}
                            onClick={() => removeCart(item._id)}
                          >
                            <img
                              src={trash}
                              alt="trash_icon"
                              style={{ marginRight: "3px" }}
                            />{" "}
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  </CustomTabPanel>
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              pt: 15,
              margin: "0 auto",
              width: { lg: "85%", md: "85%", sm: "90%", xs: "100%" },
              mb: 4,
            }}
          >
            <Typography
              sx={{ fontSize: "36px", fontWeight: 600, color: "#333" }}
            >
              Cart
            </Typography>

            {renderedCart.length === 0 ? (
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 4,
                  mt: 3,
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
              >
                <img src={emptyCart} />
              </Box>
            ) : (
              <Box sx={{ bgcolor: "#fff", p: 4, mt: 3 }}>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Product" {...a11yProps(0)} />
                      <Tab
                        label="Service"
                        sx={{ fontFamily: "inter" }}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  {/* Desktop Product Cart */}
                  <CustomTabPanel value={value} index={0}>
                    <Table>
                      <TableHead sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                        >
                          Product
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                          align="right"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                          align="right"
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                          align="right"
                        >
                          Subtotal
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {renderedCart &&
                          renderedCart.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ px: 0 }}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                  <Avatar
                                    variant="square"
                                    src={item?.product?.images[0]}
                                    sx={{ width: "70px", height: "70px" }}
                                  />
                                  <Box>
                                    <Typography
                                      sx={{
                                        color: "#121212",
                                        fontSize: "16px",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {item.product.name}
                                    </Typography>
                                    <Button
                                      sx={{ color: "#FF4C5E" }}
                                      onClick={() => removeCart(item._id)}
                                    >
                                      <img
                                        src={trash}
                                        alt="trash_icon"
                                        style={{ marginRight: "3px" }}
                                      />{" "}
                                      Remove
                                    </Button>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="right" sx={{ px: 0 }}>
                                <Box align="right">
                                  <Box
                                    sx={{
                                      border: "1px solid #ccc",
                                      borderRadius: "4px",
                                      display: "flex",
                                      width: "50%",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleDecreaseQuantity(item._id)
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <Typography
                                      sx={{ fontWeight: 600, color: "#121212" }}
                                    >
                                      {item?.quantity}
                                    </Typography>
                                    <IconButton
                                      onClick={() =>
                                        handleIncreaseQuantity(
                                          item.product._id,
                                          item.quantity
                                        )
                                      }
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="right" sx={{ px: 0 }}>
                                <Typography
                                  sx={{ color: "#333", fontSize: "16px" }}
                                >
                                  &#36;
                                  {item.pricePerUnit.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Typography>
                              </TableCell>
                              <TableCell align="right" sx={{ px: 0 }}>
                                <Typography
                                  sx={{
                                    color: "#333",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                  }}
                                >
                                  &#36;
                                  {item.totalAmount *
                                    item.quantity.toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Box align="right">
                      <Box
                        sx={{ width: "30%", bgcolor: "#F8F7F7", mt: 3, px: 2 }}
                      >
                        <Table>
                          <TableHead>
                            <TableCell
                              sx={{
                                fontSize: "15px",
                                fontFamily: "inter",
                                fontWeight: 500,
                                color: "#333",
                              }}
                            >
                              Summary
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Subtotal</TableCell>
                              <TableCell
                                align="right"
                                sx={{
                                  color: "#1a1a1a",
                                  fontWeight: 500,
                                  fontFamily: "inter",
                                }}
                              >
                                $
                                {totalSubtotal.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        {isAuthenticated ? (
                          <>
                            <Link to="/checkout">
                              <Button
                                fullWidth
                                sx={{ mt: 3, borderRadius: "100px" }}
                                variant="contained"
                              >
                                Proceed To CheckOut
                              </Button>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link to="/login">
                              <Button
                                variant="contained"
                                fullWidth
                                size="large"
                                sx={{ mt: 3, borderRadius: "100px" }}
                              >
                                Sign in to proceed
                              </Button>
                            </Link>
                            <Typography
                              sx={{ textAlign: "center", fontSize: "12px" }}
                            >
                              New to Mamaket?{" "}
                              <Link to="/register">Sign up </Link>
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                  </CustomTabPanel>

                  {/* Service Cart */}

                  <CustomTabPanel value={value} index={1}>
                    <Table>
                      <TableHead sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                        >
                          Service
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                          align="right"
                        >
                          Appointment Date
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "15px",
                            fontFamily: "inter",
                            fontWeight: 500,
                            color: "#333",
                            px: 0,
                          }}
                          align="right"
                        >
                          Price
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {cartProducts.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={{ px: 0 }}>
                              <Box sx={{ display: "flex", gap: 2 }}>
                                <Avatar
                                  variant="square"
                                  src={item?.images[0]}
                                  sx={{ width: "70px", height: "70px" }}
                                />
                                <Box>
                                  <Typography
                                    sx={{
                                      color: "#121212",
                                      fontSize: "16px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Button
                                    sx={{ color: "#FF4C5E" }}
                                    onClick={() => removeCart(item._id)}
                                  >
                                    <img
                                      src={trash}
                                      alt="trash_icon"
                                      style={{ marginRight: "3px" }}
                                    />{" "}
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell align="right" sx={{ px: 0 }}>
                              <Typography
                                sx={{ color: "#333", fontSize: "16px" }}
                              >
                                &#36;
                                {item.price.toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ px: 0 }}>
                              <Typography
                                sx={{
                                  color: "#333",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                &#36;
                                {(item.price * item.quantity).toLocaleString(
                                  undefined,
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CustomTabPanel>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}

      <Newsletter />
      <Footer />
    </>
  );
};

export default Cart;
