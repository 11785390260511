import React from 'react'
import SideNav from './SideNav'
import { Outlet } from 'react-router-dom'
import Navbar from '../../Components/Common/Navbar'
import BreadCrumbs from '../../Components/Common/BreadCrumbs'
import { Box } from '@mui/material'
import Newsletter from '../../Components/Common/Newsletter'
import Footer from '../../Components/Common/Footer'

const SharedLayouts = () => {
  return (
    <>
      <Navbar />
      <Box sx={{margin:'0 auto', width:{lg:'85%', md:'85%', sm:'95%', xs:'95%'}, mt: 15 }}>
        <BreadCrumbs />
      <Box sx={{ bgcolor: "#fff", display: "flex", mt: 2, p: {lg:5, md:5, sm:0, xs:0}, mb:4 }}>
        <Box sx={{ width: "25%", display:{lg:'block', md:'block', sm:'none', xs:'none'} }}>
          <SideNav />
        </Box>
        <Box sx={{ bgcolor: "#f8f7f7" , width:{lg:'75%', md:'75%', sm:'100%', xs:'100%'}, mx:0, p:{lg:3, md:3, sm:1, xs:0}}}>
          <Outlet />
        </Box>
          </Box>
      </Box>
          <Newsletter />
          <Footer/>
    </>
  );
}

export default SharedLayouts