import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import img1 from "../../../assets/Products/1.png";
import nigFlag from "../../../assets/Flags/Japan.svg";
import "../../../STYLES/main.css";
import whatsapp from "../../../assets/Icons/whatsapp 1.svg";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import twitter from "../../../assets/Icons/twitter 1 (1).svg";
import facebook from "../../../assets/Icons/facebook 1 (1).svg";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import love from "../../../assets/Icons/love.svg";
import { add } from "../../../store/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import Loader from "../../Common/Loader";
import { GET_SINGLE_SERVICE } from "../../../Utils/Queries";
import noImage from "../../../assets/no-image.png";
import { formatDistanceToNow } from "date-fns";
import { increaseQuantity, decreaseQuantity } from "../../../store/cartSlice";
import { addFav } from "../../../store/favouriteSlice";
import moment from "moment";
import BreadCrumbs from "../../Common/BreadCrumbs";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const Overview = (serviceID) => {
  const [open, setOpen] = React.useState(false);
  const [openBook, setOpenBook] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseBook = () => setOpenBook(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);

      // alert("sd")
    };

    const handleClosePopper = () => {
      setAnchorEl(null);
    };

    const openPopper = Boolean(anchorEl);
  const id = openPopper ? "simple-popover" : undefined;
    const handleOpenBook = () => {
      setOpenBook(true);
      setAnchorEl(null);
    };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [productImage, setProductImage] = useState(img1);
  const [productQuantity, setProductQuantity] = useState(1);
  const cartProducts = useSelector((state) => state.cart);

  const handleIncreaseQuantity = (id) => {
    dispatch(increaseQuantity(id));
  };
  const handleDecreaseQuantity = (id) => {
    dispatch(decreaseQuantity(id));
  };

  const { loading, error, data } = useQuery(GET_SINGLE_SERVICE, {
    variables: {
      _id: serviceID?.serviceID, // Replace with the actual dynamic _id value
    },
  });

  console.log(data, "=> SINGLE SERVICE");

  const product = data?.listAllAvailableServicesWithoutAuthentication?.items[0];

  const addCart = (product) => {
    dispatch(add(product));
  };
  const handleaddFav = (product) => {
    dispatch(addFav(product));
  };
  const reduceQuantity = () => {
    if (productQuantity >= 2) {
      setProductQuantity(productQuantity - 1);
    }
  };
  const isAuthenticated = localStorage.getItem("isAuthenticated") === "true";

  const handleToLogin = () => {
    navigate("/login");
  };
  return (
    <>
      {loading && <Loader />}
      <Box
        sx={{
          p: 1,
          px: 2,
          bgcolor: "#fff",
          fontFamily: "inter",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Link
            to="/"
            style={{ fontWeight: 400, color: "#666", fontFamily: "inter" }}
          >
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
                fontFamily: "inter",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Home
            </Typography>
          </Link>
          <NavigateNextIcon />
          <Link to="/">
            <Typography
              sx={{
                color: "#666",
                fontWeight: 500,
                fontFamily: "inter",
                fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
              }}
            >
              Service
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          width: "100%",
          bgcolor: "#fff",
          mt: { lg: 5, md: 5, sm: 0, xs: 0 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: {
                    lg: "row",
                    md: "row",
                    sm: "column-reverse",
                    xs: "column-reverse",
                  },
                }}
              >
                <Grid
                  item
                  lg={1.5}
                  md={1.5}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: {
                      lg: "column",
                      md: "column",
                      sm: "row",
                      xs: "row",
                    },
                    gap: 2,
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Avatar
                      src={product?.images[0] ? product?.images[0] : noImage}
                      variant="square"
                      sx={{ width: "100%", height: "50px" }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={10.5}>
                  <Avatar
                    src={product?.images[0] ? product?.images[0] : noImage}
                    variant="square"
                    sx={{ width: "100%", height: "400px" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Info Product */}
            <Grid item lg={7} md={7} sm={12} xs={12} sx={{}}>
              <Box sx={{}}>
                <Box
                  sx={{
                    px: "12px",
                    py: "0px",
                    borderBottom: "1px solid #f2f2f2",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                      Product Origin:
                    </Typography>
                    <img
                      src={
                        product?.city?.state?.country?.flagUrl
                          ? product?.city?.state?.country?.flagUrl
                          : nigFlag
                      }
                      width={20}
                    />
                  </Box>
                  <Typography
                    // className="product_title"
                    sx={{
                      fontSize: {
                        lg: "26px",
                        md: "26px",
                        sm: "20px",
                        xs: "20px",
                        color: "#333",
                        fontWeight: 500,
                      },
                      mt: 1,
                    }}
                  >
                    {product?.title}
                  </Typography>
                  <Box sx={{ mt: 1.5, display: "flex", alignItems: "center" }}>
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 500, color: "#333" }}
                    >
                      &#36; {product?.price.toLocaleString()}
                    </Typography>
                    {product?.isNegotiable === true ? (
                      <Typography
                        sx={{
                          bgcolor: "#E9FCF9",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Negotiable
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          bgcolor: "#E9FCF9",
                          py: 0.2,
                          px: 1,
                          borderRadius: "5px",
                          ml: 2,
                          color: "#148B77",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        Buy Now
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: { lg: 2, md: 2, sm: 1, xs: 1 },
                      color: "#333",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      {`${product?.city?.name}, ${product?.city?.state?.name}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Views: 200
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      |
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333",
                        fontSize: {
                          lg: "12px",
                          md: "12px",
                          sm: "11px",
                          xs: "10px",
                        },
                        fontWeight: 400,
                      }}
                    >
                      Posted:{" "}
                      {product?.createdAt &&
                        formatDistanceToNow(new Date(product?.createdAt), {
                          addSuffix: true,
                        })}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    borderBottom: "1px solid #f2f2f2",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    py: "20px",
                    width: "100%",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      rowGap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#333",
                        mr: 1,
                        ml: 1,
                      }}
                    >
                      Share with friends
                    </Typography>
                    <IconButton>
                      <img src={facebook} alt="facebook_icon" />
                    </IconButton>
                    <IconButton>
                      <img src={twitter} alt="twitter_icon" />
                    </IconButton>
                    <IconButton>
                      <img src={whatsapp} alt="whatsapp_icon" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Button
                      onClick={handleOpen}
                      variant="contained"
                      sx={{
                        bgcolor: "#3FB763",
                        color: "#fff",
                        borderRadius: "100px",
                      }}
                    >
                      Make an offer <TextsmsOutlinedIcon sx={{ ml: 1 }} />
                    </Button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    py: "24px",
                    px: "12px",
                    borderBottom: "1px solid #f2f2f2",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 500, color: "#333", fontSize: "12px" }}
                  >
                    Quantity :
                  </Typography>
                  <Box
                    sx={{
                      bgcolor: "#f5f5f5",
                      padding: "12px 8px",
                      display: "flex",
                      alignItems: "center",
                      ml: 2,
                    }}
                  >
                    <IconButton
                      sx={{ color: "#333", fontSize: "12px", padding: "2px" }}
                      onClick={() => handleDecreaseQuantity(product._id)}
                    >
                      <RemoveOutlinedIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        color: "#333",
                        fontSize: "12px",
                        mx: 1,
                      }}
                    >
                      {productQuantity}
                    </Typography>
                    <IconButton
                      sx={{ color: "#333", fontSize: "12px", padding: "2px" }}
                      onClick={() => handleIncreaseQuantity(product._id)}
                    >
                      <AddOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>

                <Box
                  sx={{
                    pt: "24px",
                    px: { lg: "12px", md: "12px", xs: "0px", sm: "0px" },
                    display: "flex",
                    flexDirection: {
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    aria-describedby={id}
                    sx={{
                      width: "350px",
                      borderRadius: "100px",
                      padding: "12px 20px",
                    }}
                    onClick={isAuthenticated ? handleClick : handleToLogin}
                  >
                    Chat Or Book appointment
                  </Button>
                  <Button
                    onClick={
                      isAuthenticated
                        ? () => handleaddFav(product)
                        : handleToLogin
                    }
                    sx={{ color: "#333", fontSize: "16px" }}
                  >
                    <img
                      src={love}
                      alt="fav_icon"
                      style={{ marginRight: "10px" }}
                    />
                    Save For Later
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Mmake an Offer Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
            >
              Make an offer
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseOutlined sx={{ color: "#333" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2, borderBottom: "1px solid #E6E6E6", pb: 2 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  // marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  border: "1px dashed #333",
                  width: "fit-content",
                  color: "#333",
                  borderRadius: 3,
                  px: 2,
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                ${(product?.price - 10).toLocaleString()}
              </Button>
              <Button
                sx={{
                  border: "1px dashed #333",
                  width: "fit-content",
                  color: "#333",
                  borderRadius: 3,
                  px: 2,
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                ${(product?.price - 15).toLocaleString()}
              </Button>
              <Button
                sx={{
                  border: "1px dashed #333",
                  width: "fit-content",
                  color: "#333",
                  borderRadius: 3,
                  px: 2,
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                ${(product?.price - 20).toLocaleString()}
              </Button>
              <Button
                sx={{
                  border: "1px dashed #333",
                  width: "fit-content",
                  color: "#333",
                  borderRadius: 3,
                  px: 2,
                  fontWeight: 400,
                  cursor: "pointer",
                }}
              >
                ${(product?.price - 25).toLocaleString()}
              </Button>
            </Box>
          </Box>
          <Box sx={{ py: 2 }}>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              Schedule date & time
            </Typography>

            <Grid container spacing={2}>
              <Grid item lg={6}>
                <TextField
                  fullWidth
                  type="date"
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  fullWidth
                  type="time"
                  InputProps={{
                    style: {
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ borderRadius: "100px" }}
            >
              Send Offer
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Makke an offer modal */}

      {/* Buttonn Poppeer */}
      <Popover
        id={id}
        open={openPopper}
        anchorEl={anchorEl}
        onClose={handleClosePopper}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Link to="/chat">
            <MenuItem>Chat with service provider</MenuItem>
          </Link>
          <MenuItem onClick={handleOpenBook}>Book An Appointment </MenuItem>
        </Box>
      </Popover>

      {/* Buttonn Poppeer */}

      {/* Book an Offer Modal */}
      <Modal
        open={openBook}
        onClose={handleCloseBook}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, color: "#333", fontSize: "16px" }}
            >
              Book Appointment
            </Typography>
            <IconButton onClick={handleCloseBook}>
              <CloseOutlined sx={{ color: "#333" }} />
            </IconButton>
          </Box>

          <Box sx={{ py: 2 }}>
            <Typography
              sx={{
                color: "#333",
                fontWeight: 400,
                fontSize: "12px",
              }}
            >
              Schedule date & time
            </Typography>

            <Grid container spacing={2}>
              <Grid item lg={6}>
                <TextField
                  fullWidth
                  type="date"
                  InputProps={{
                    style: {
                      //   fontFamily: "Gordita",
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <TextField
                  fullWidth
                  type="time"
                  InputProps={{
                    style: {
                      marginTop: "10px",
                      // marginBottom: "20px",
                      fontSize: "13px",
                      borderRadius: "8px",
                      offset: " 1px solid #f2f2f2",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Button
              fullWidth
              variant="contained"
              sx={{ borderRadius: "100px" }}
            >
              Send Offer
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Book an offer modal */}
    </>
  );
};

export default Overview;
