import React from "react";
import { useRoutes } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import Register from "./Pages/Auth/Register";
import Verify from "./Pages/Auth/Verify";
import CreatePin from "./Pages/Auth/CreatePin";
import ForgetPassword from "./Pages/Auth/ForgetPassword";
import Home from "./Pages/Screens/Home";
import Download from "./Pages/Screens/Download";
import ProductView from "./Pages/Screens/ProductView";
import CategoryView from "./Pages/Screens/CategoryView";
import Cart from "./Components/Cart/Cart";
import Wishlist from "./Pages/Screens/Wishlist";
import {
  Profile,
  SharedLayouts,
  Notification,
  Security,
  Address,
  Mamapurse,
  HelpCenter,
  MyOrders,
} from "./Account-Profile/Pages";
import {
  About,
  Ads,
  Contact,
  Faq,
  Mamapurse as MamapurseFooter,
  Payment,
  PrivacyPolicy,
  ProductDoc,
  ReturnPolicy,
  TermsConditions,
  Verification,
} from "./FooterContent";
import Chat from "./Chat/Chat";
import ChatOutlet from "./Chat/ChatOutlet";
import OrderList from "./Account-Profile/Pages/MyOrder-Component/OrderList";
import OrderDetails from "./Account-Profile/Pages/MyOrder-Component/OrderDetails";
import ProtectedRoute from "./Utils/ProtectedRoutes";
import Checkout from "./Components/CheckOut/Checkout";
import Confirmation from "./Account-Profile/Components/Mamapurse/Confirmation";
import NoPage from "./Pages/Screens/NoPage";
import ServiceView from "./Pages/Screens/ServiceView";
import RegisterMain from "./Pages/Auth/RegisterMain";
import Blogs from "./FooterContent/Blog/Blogs";
import MyAccount from "./Account-Profile/Layouts/MyAccount";

export function Routes() {
  return useRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/checkout",
      element: <Checkout />,
    },
    {
      path: "/download",
      element: <Download />,
    },
    {
      path: "/product-view/:id",
      element: <ProductView />,
    },
    {
      path: "/service-view/:id",
      element: <ServiceView />,
    },
    {
      path: "/product-view",
      element: <CategoryView />,
    },
    {
      path: "/wishlist",
      element: <Wishlist />,
    },
    {
      path: "/cart",
      element: <Cart />,
    },
    {
path:'/blog',
element:<Blogs/>
    },
    {
      path: "/chat",
      element: (
        <ProtectedRoute>
          <Chat />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/chat/:id",
          element: <ChatOutlet />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <RegisterMain />,
    },
    {
      path: "/register-form",
      element: <Register />,
    },
    {
      path: "/verify",
      element: <Verify />,
    },
    {
      path: "/createpin",
      element: <CreatePin />,
    },
    {
      path: "/about-us",
      element: <About />,
    },
    {
      path: "/ads",
      element: <Ads />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    {
      path: "/faq",
      element: <Faq />,
    },
    {
      path: '*',
      element:<NoPage/>
    },
    {
      path: "/mamapurse",
      element: <MamapurseFooter />,
    },
    {
      path: "/footer-product",
      element: <ProductDoc />,
    },
    {
      path: "/payment",
      element: <Payment />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/product-policy",
      element: <ProductDoc />,
    },
    {
      path: "/return-policy",
      element: <ReturnPolicy />,
    },
    {
      path: "/terms&conditions",
      element: <TermsConditions />,
    },
    {
      path: "/verification",
      element: <Verification />,
    },
    {
      path: "/account/mamapurse/confirmation",
      element: <Confirmation />,
    },
    {
      path: "/my-account",
      element: <MyAccount />,
    },
    {
      path: "/forget_password",
      element: <ForgetPassword />,
    },
    {
      element: (
        <ProtectedRoute>
          {" "}
          <SharedLayouts />
        </ProtectedRoute>
      ),
      children: [
        {
          element: <Profile />,
          path: "/account",
        },
        {
          element: <Notification />,
          path: "/account/notification",
        },
        {
          element: <Security />,
          path: "/account/security",
        },
        {
          element: <Address />,
          path: "/account/address",
        },
        {
          element: <Mamapurse />,
          path: "/account/mamapurse",
        },
        {
          element: <HelpCenter />,
          path: "/account/help-center",
        },
        {
          element: <MyOrders />,
          // path: "/account/orders",
          children: [
            {
              element: <OrderList />,
              path: "/account/orders",
            },
            {
              element: <OrderDetails />,
              path: "/account/orders/order-details/:id",
            },
          ],
        },
      ],
    },
  ]);
}
