import {
  Box,
  IconButton,
  Typography,
  Chip,
  Avatar,
  Grid,
  Modal,
  createTheme,
  ThemeProvider,
  Select,
  MenuItem,
  TextField,
  InputLabel,
} from "@mui/material";
import { parseISO, format } from "date-fns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link, useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import timeIcon from "../../../assets/Icons/time.svg";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SINGLE_ORDER, } from "../../../Utils/Queries";
import Loader from "../../../Components/Common/Loader";
import { CANCEL_ORDER } from "../../../Utils/Mutations";
import declinedImg from '../../../assets/Icons/declined 2.svg'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {lg:400, md:400, sm:300, xs:300},
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

const themeStepper = createTheme({
  palette: {
    primary: {
      main: "#349852",
    },
  },
});
const steps = [
  {
    label: "Payment received",
    description: "10-02-2023.",
  },
  {
    label: "Order Processing",
    description: "10-02-2023",
  },
  {
    label: "Shipped",
    description: ``,
  },
  {
    label: "Delivered",
    description: ``,
  },
  {
    label: "Review & Release Fund",
    description: ``,
  },
];
const OrderDetails = () => {
  const {id} = useParams()
 const [rejectReasons, setRejectReasons] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeStep, setActiveStep] = React.useState(4);
  const [description, setDescription] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };
    const handleChange = (event) => {
      setRejectReasons(event.target.value);
    };


  const formatDate = (dateString) => {
    const parsedDate = parseISO(dateString);
    const formattedDate = format(parsedDate, "iiii, do 'of' MMMM yyyy, h:mm a");
    return formattedDate;
  };

  const [getSingleOrder, {loading, data}] = useLazyQuery(GET_SINGLE_ORDER)
  useEffect(()=>{
getSingleOrder({
  variables:{
    reference:id
  }
})
  }, [id])
  console.log(data?.getMyOrders?.items[0]);
  const { amountPaid, createdAt, quantity, product, statusHistory, _id } =
    data?.getMyOrders?.items[0] || [];

  useEffect(()=>{
    if(statusHistory){
if(statusHistory[0].status === "Paid"){
setActiveStep(0)
}
    }
  },[statusHistory])

  const [cancelOrder, {loading:cancelLoad, data:cancelData}] = useMutation(CANCEL_ORDER)

  const handleCancelOrder = () =>{
    setSuccess(false)
    setIsLoading(true)
    cancelOrder({
      variables:{
        itemId:_id,
        reason:rejectReasons,
        description
      }
    }).then((res)=>{
  //  alert("success")
   setIsLoading(false)
   setSuccess(true)
    }).catch((err) => {
      console.log(err)
   setIsLoading(false);
    })
  }
  return (
    <>
      {cancelLoad || (isLoading && <Loader />)}

      {loading && <Loader />}
      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          flexDirection: {
            lg: "row",
            md: "row",
            sm: "column",
            xs: "column",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="/account/orders">
            <ArrowBackIosIcon
              sx={{ color: "#1a1a1a", fontSize: "15px", mb: -0.2 }}
            />
          </Link>
          <Typography sx={{ fontSize: "12px", ml: 2 }}>
            Order ID:
            <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
              {" " + id}
            </span>
          </Typography>
        </Box>
        <Typography sx={{ fontSize: "12px", color: "#666" }}>
          Purchase date:{" "}
          <span style={{ fontWeight: 600, color: "#1a1a1a" }}>
            {createdAt && formatDate(createdAt)}
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          p: 1,
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: 2,
            width: "fit-content",
          }}
        >
          <Avatar
            variant="square"
            src={product && product.images[0]}
            sx={{ width: "80px", height: "60px" }}
          />
          <Box>
            <Typography
              sx={{
                color: "#1a1a1a",
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {product && product.name}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 400,
                }}
              >
                QTY: {data && quantity}
              </Typography>
              <Typography
                sx={{
                  color: "#1a1a1a",
                  fontSize: "12px",
                  fontWeight: 400,
                  ml: 5,
                }}
              >
                ${amountPaid && amountPaid}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          {activeStep <= 1 && <Chip label="confirmed" sx={{ px: 2 }} />}
          {activeStep === 2 && (
            <Chip
              label="Shipped"
              sx={{ color: "#FFC533", bgcolor: "#FFC5331a", px: 2 }}
            />
          )}
          {activeStep >= 4 && (
            <Chip
              label="Delivered"
              sx={{ color: "#3FB763", bgcolor: "#3FB7631a", px: 2 }}
            />
          )}
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ mt: 0 }}>
        <ThemeProvider theme={themeStepper}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Box sx={{ bgcolor: "#fff", p: 2 }}>
              <Typography>Status</Typography>
              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label} sx={{ fontFamily: "inter" }}>
                      <StepLabel>
                        <Typography
                          sx={{
                            fontFamily: "inter",
                            color: "#333",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          {step.label}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Typography
                          sx={{
                            fontFamily: "inter",
                            color: "#666",
                            fontSize: "12px",
                          }}
                        >
                          {step.description}
                        </Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>
          </Grid>
        </ThemeProvider>
        <Grid item lg={8} md={8} sm={12} xs={12}>
          {activeStep === 5 && (
            <>
              <Box
                sx={{
                  bgcolor: "#fff",
                  p: 2,
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography>
                  Are you satisfied with the product delivered?
                </Typography>
                <Button sx={{ border: "1px solid #E6E6E6", color: "#333" }}>
                  No, Dispute
                </Button>
                <Button sx={{ bgcolor: "#3FB763" }} variant="contained">
                  Yes, Release Fund
                </Button>
              </Box>
            </>
          )}
          <Box sx={{ bgcolor: "#fff", p: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
              Shipping
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Delivery Type:</Typography>
              <Typography sx={{ fontWeight: 400, color: "#333" }}>
                Home Delivery
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ width: "fit-content" }}>
                Delivery Address:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: "#333",
                  fontSize: "14px",
                  textAlign: "right",
                }}
              >
                Blk. A-B Jl. Cikutra No.125, Cikutra, Kec. Cibeunying
                <br /> Kidul, Charlotte, North Carolina 40124
              </Typography>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", p: 2, mt: 2 }}>
            <Typography sx={{ color: "#333", fontWeight: 500 }}>
              Payment
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Amount:</Typography>
              <Typography sx={{ fontWeight: 400, color: "#333" }}>
                ${amountPaid && amountPaid.toLocaleString()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ width: "fit-content" }}>
                Payment Method:
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: "#333",
                  fontSize: "14px",
                  textAlign: "right",
                }}
              >
                Mamapurse
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              bgcolor: "#fff",
              p: 2,
              mt: 2,
              display: "grid",
              placeItems: "center",
            }}
          >
            {activeStep === 0 ? (
              <Button
                onClick={handleOpen}
                color="error"
                sx={{ textDecoration: "underline", fontWeight: 600 }}
              >
                CANCEL ORDER
              </Button>
            ) : (
              <>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img src={timeIcon} />
                  <Typography
                    sx={{
                      ml: 1,
                      color: "#333",
                      fontWeight: 400,
                      fontSize: "14px",
                    }}
                  >
                    Need help?
                    <Link to="/" style={{ color: "#148B77", fontWeight: 500 }}>
                      {" "}
                      Contact Support{" "}
                    </Link>
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {success ? (
            <>
              <Box align="center" sx={{p:5}}>
                <img src={declinedImg}/>
                <Typography sx={{color:'#333', fontWeight:500}}>Order Cancelled</Typography>
                <Typography sx={{color:'#333', mt:2}}>
                  A refund will be issued and credited to your MamaPurse wallet.
                </Typography>
                <Button variant="contained" onClick={handleClose} sx={{mt:3}}>Done</Button>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#333", fontWeight: 400 }}>
                  Cancel Order
                </Typography>
                <IconButton onClick={handleClose}>
                  {" "}
                  <ClearIcon sx={{ color: "#333" }} />
                </IconButton>
              </Box>
              <Typography sx={{ mt: 2, color: "#333", lineHeight: "145%" }}>
                By clicking "cancel order," you confirm the cancellation of your
                purchase. A refund will be issued and credited to your MamaPurse
                wallet.
              </Typography>
              <Box sx={{ mt: 3 }}>
                <Select
                  displayEmpty
                  fullWidth
                  sx={{
                    borderRadius: "10px",
                    background: "#fff",
                    offset: " 0px solid #737373",
                    border: "0px solid red",
                  }}
                  InputProps={{
                    style: {
                      fontFamily: "inter",
                      fontSize: "13px",

                      // Replace with your desired font family
                    },
                  }}
                  value={rejectReasons}
                  onChange={handleChange}
                >
                  <MenuItem value="" disabled>
                    Reason for cancellation
                  </MenuItem>
                  <MenuItem value="Better Alternative ">
                    Better Alternative{" "}
                  </MenuItem>
                  <MenuItem value="High Cost ">High Cost </MenuItem>
                  <MenuItem value="Dissatisfaction with quality">
                    {" "}
                    Dissatisfaction with quality{" "}
                  </MenuItem>
                  <MenuItem value="Others "> Others </MenuItem>
                </Select>

                <TextField
                  fullWidth
                  multiline
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={6}
                  margin="normal"
                  placeholder="more description"
                  InputProps={{
                    style: {
                      fontFamily: "inter",
                      fontSize: "13px",
                      borderRadius: "10px",
                      background: "#fff",
                      offset: " 0px solid #737373",
                      border: "0px solid red",
                      // Replace with your desired font family
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: 3,
                }}
              >
                <Button
                  fullWidth
                  sx={{ border: "1px solid #E6E6E6", color: "#333" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  fullWidth
                  color="error"
                  onClick={handleCancelOrder}
                  variant="contained"
                  disabled={rejectReasons === "" || !description}
                >
                  Yes, Cancel
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default OrderDetails;
