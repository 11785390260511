import { Box, Chip, IconButton } from '@mui/material'
import React, {useRef, useState} from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';



const categoriesList = [
    {
        title:'Electronic',
     
    },
    {
        title:'Grocery',
    
    },
    {
        title:'Clothing',
     
    },
    {
        title:'Baby & Kids',
   
    },
    {
        title:'Sports',
      
    },
    {
        title:'Books',

    },
    {
        title:'IT Services',

    },
    {
        title:'Cleaning Services',
   
    },
    {
        title:'Travel Agents',

    },
    {
        title:'Automotive Services',

    },
    {
        title:'Automotive Services',
    
    },
    {
        title:'Automotive Services',

    },
]
const CategoriesBanner = () => {

    const [show, setShow] = useState(false)

    const containerRef = useRef(null);

    const handleScrollRight = () => {
      const container = containerRef.current;
      container.scrollBy({ left: 100, behavior: 'smooth' });
      setShow(true)
    };
    const handleScrollLeft = () => {
      const container = containerRef.current;
      container.scrollBy({ left: -100, behavior: 'smooth' });
    };
  return (
    <>
      <Box
        sx={{ bgcolor: "#fff", p: 2, margin: "0 auto ", width: {lg:"85%", md:'85%', sm:'90%', xs:'90%'}, my: {lg:3, md:3, sm:0, xs:0} }}
      >
        {/* <Typography variant='subtitle2'>Categories</Typography> */}
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            alignItems: "center",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none",
          }}
        >
          {categoriesList.map((category, index) => (
       
              <Chip
                key={index}
                label={category.title}
                variant="outlined"
                sx={{ mx: {xs:1, sm:1, md:2, lg:2}, fontFamily: "inter" }}
              />
  
          ))}
        </Box>
        <Box sx={{ display: {lg:"flex", md:'flex', sm:'none', xs:'none'}, justifyContent: "space-between", mt: -4 }}>
          <Box>
            {show && (
              <IconButton
                onClick={handleScrollLeft}
                sx={{
                  border: "1px solid #148B77",
                  height: "30px",
                  width: "30px",
                }}
              >
                <KeyboardArrowLeftIcon
                  sx={{
                    background: "#148b77",
                    borderRadius: "50%",
                    color: "#fff",
                  }}
                />
              </IconButton>
            )}
          </Box>
          <IconButton
            onClick={handleScrollRight}
            sx={{ border: "1px solid #148B77", height: "30px", width: "30px" }}
          >
            <KeyboardArrowRightIcon
              sx={{ background: "#148b77", borderRadius: "50%", color: "#fff" }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default CategoriesBanner
