import React, { useEffect, useState } from "react";
import { Box, Button, Skeleton, Typography, Modal, TextField } from "@mui/material";
import walletIcon from "../../assets/Icons/Wallet.svg";
import AddCash from "../../Account-Profile/Components/Mamapurse/AddCash";
import { useSelector } from "react-redux";
import { GET_ALL_PUSRSES } from "../../Utils/Queries";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../../Utils/Mutations";
import Loader from "../Common/Loader";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
borderRadius:2,
  boxShadow: 24,
  p: 2,
};



const Payment = ({selectedAddress, routeConfirmed}) => {
  const [selected, setSelected] = useState(false);
  const [selected2, setSelected2] = useState(false);
  const [insufficient, setInsufficient]  = useState(false)
  const [transactionPin, setTransactionPin] = useState("")
   const [open, setOpen] = React.useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);
   const [isLoading, setIsLoading] = useState(false)
   const [success, setSuccess] = useState(false)
   const [error, setError] = useState(null)
  const handleSelect = () => {
    setSelected(!selected);
    setSelected2(false);
  };
  const handleSelect2 = () => {
    setSelected2(!selected2);
    setSelected(false);
  };
  const [getWallets, { data: wallets, loading }] =
    useLazyQuery(GET_ALL_PUSRSES);

      useEffect(() => {
        getWallets();

      }, []);
    

        const spendingBalance = wallets?.getWallets[0]?.spendingBalance;

  
    const cartProducts = useSelector((state) => state.cart);

    const totalSubtotal = cartProducts.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
  useEffect(() => {
    if (spendingBalance) {
      if (totalSubtotal > spendingBalance) {
        setInsufficient(true);
      } else setInsufficient(false);

    }
  },[])

  const [createOrder, {data}] = useMutation(CREATE_ORDER)

  const handleCreateOrder = () =>{
    // setOpen(false)
    setError(null)

    setIsLoading(true)
    createOrder({
      variables:{
        deliveryOption:"HOME",
        addressOne:selectedAddress.addressOne,
        country:selectedAddress.country,
        state:selectedAddress.state,
        addressTwo:selectedAddress.addressTwo,
        city:selectedAddress.city,
        zipCode:selectedAddress.zipCode,
        transactionPin:transactionPin
      }
    }).then((res)=>{
      // console.log(res)
      setIsLoading(false)
      routeConfirmed()
    }).catch((err)=>{
      console.log(err)
            setIsLoading(false);
            setError("Invalid Transaction Pin")
    })
  }
    console.log(typeof(selectedAddress.city));
  return (
    <>
      <Box sx={{ mt: 3, pb: 3 }}>
        {/* <Typography sx={{ color: "#333", fontSize: "16px", fontWeight: 500 }}>
          Select shipping option
        </Typography> */}
        <Box sx={{ width: "100%", mt: 1 }}>
          {/* <Box
            onClick={handleSelect}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: selected ? "#E9FCF9" : "#f8f7f7",
              py: 1,
              px: 2,
              border: selected && "1px solid #148B77",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  width: selected ? "8px" : "20px",
                  height: selected ? "8px" : "20px",
                  border: selected ? "6px solid #148B77" : "1px solid #ccc",
                }}
              ></Box>
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Door Dash
              </Typography>
            </Box>
            <Typography>$10.50</Typography>
          </Box>
          <Box
            onClick={handleSelect2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: selected2 ? "#E9FCF9" : "#f8f7f7",
              py: 1,
              px: 2,
              mt: 2,
              border: selected2 && "1px solid #148B77",
              borderRadius: "2px",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", columnGap: 2 }}>
              <Box
                sx={{
                  borderRadius: "50%",
                  width: selected2 ? "8px" : "20px",
                  height: selected2 ? "8px" : "20px",
                  border: selected2 ? "6px solid #148B77" : "1px solid #ccc",
                }}
              ></Box>
              <Typography
                sx={{ color: "#1a1a1a", fontWeight: 500, fontSize: "14px" }}
              >
                Uber Eat
              </Typography>
            </Box>
            <Typography>$12.50</Typography>
          </Box> */}

          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 500 }}
            >
              Summary
            </Typography>
            <Box sx={{ bgcolor: "#f8f7f7", mt: 2, p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Items Price (X10)
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $160.00
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Shipping Fee
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $10.50
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333",
                  py: 0.5,
                }}
              >
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Tax
                </Typography>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  $16.00
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#333333",
                  py: 0.5,
                }}
              >
                <Typography
                  sx={{ color: "#333", fontSize: "13px", fontWeight: 500 }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{ color: "#333", fontSize: "13px", fontWeight: 500 }}
                >
                  {totalSubtotal ? "$" + totalSubtotal.toLocaleString() : "--"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{ color: "#333", fontSize: "16px", fontWeight: 500 }}
            >
              Pay with
            </Typography>
            <Box sx={{ bgcolor: "#f8f7f7", mt: 2, p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{ display: "flex", columnGap: 2, alignItems: "center" }}
                >
                  <img src={walletIcon} />
                  <Box>
                    <Typography sx={{ color: "#333", fontWeight: 500 }}>
                      Mamapurse
                    </Typography>
                    {loading ? (
                      <Skeleton variant="text" />
                    ) : (
                      <Typography sx={{ color: "#333", fontWeight: 500 }}>
                        {`$${
                          spendingBalance
                            ? spendingBalance.toLocaleString()
                            : "0"
                        }.00`}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <AddCash
                  text="Add Cash"
                  color="#3FB763"
                  variant="standard"
                  bgcolor="none"
                />
                {/* <Button sx={{ color: "#3FB763" }}>Top-up account</Button> */}
              </Box>
            </Box>
          </Box>

          {insufficient && (
            <Box
              sx={{
                padding: "12px 16px",
                border: "1px dashed #FF6675",
                bgcolor: "#FFE5E8",
                mt: 1.5,
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{ color: "#FF3347", fontSize: "11px", lineHeight: "125%" }}
              >
                <span> Important Notice:</span> Your Mamapurse balance is
                insufficient to cover your check total. To add funds to your
                Mamapurse account, please click on "Top Up Account." Thank you.
              </Typography>
            </Box>
          )}
        </Box>
        <Button
          onClick={handleOpen}
          variant="contained"
          disabled={insufficient}
          sx={{
            color: "#fff",
            mt: 4,
            borderRadius: "100px",
            padding: "13px 24px",
          }}
          fullWidth
        >
          Check Out
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading && <Loader />}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter Transaction PIN
          </Typography>
          <Typography id="modal-modal-description" sx={{}}>
            Enter your 4 digit pin to authorize this transactions.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <TextField
              type="password"
              fullWidth
              error={error}
              value={transactionPin}
              helperText={error}
              onChange={(e) => setTransactionPin(e.target.value)}
              placeholder="Enter Pin"
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  mt: 1.5,
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3 }}
            onClick={handleCreateOrder}
            disabled={transactionPin.length < 4}
          >
            Confirm
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Payment;
