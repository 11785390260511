import { Box, Typography, Divider, IconButton, Button, Popover, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import emptyAddress from '../../assets/no-address.svg'
import AddAddress from "../Components/Address/AddAddress";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_ADDRESS } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";

const Address = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  
  // const [addressData, setAddressData] = useState(true)

  const [getAddress, { loading, data }] = useLazyQuery(GET_ALL_ADDRESS)
  useEffect(() => {
    getAddress()
  }, [])
  console.log(data, "=>Address")
  return (
    <>
      {loading && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#333", fontWeight: 500, fontSize: "16px" }}>
            Address
          </Typography>
          <AddAddress />
        </Box>
        <Divider sx={{ my: 2 }} />
        {data?.fetchAllAddresses.map((item, index) => (
          <Box
            key={index}
            sx={{
              my: 2,
              px: 2,
              display: "flex",
              justifyContent: "space-between",
              boxShadow: "0px 4px 60px 0px rgba(4, 6, 15, 0.05)",
            }}
          >
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#333" }}
              >
                {`${item?.firstName} ${item?.lastName}`}
              </Typography>
              <Typography
                sx={{ fontWeight: 500, fontSize: "14px", color: "#333" }}
              >
                {item?.addressOne}
              </Typography>
              <Typography
                sx={{ fontWeight: 300, fontSize: "12px", color: "#333" }}
              >
                {item?.phone}
              </Typography>
            </Box>
            <Box>
              {item?.isDefault && (
                <Typography sx={{ color: "#3FB763", fontSize: "12px" }}>
                  (Default)
                </Typography>
              )}

              <IconButton onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
        {!data?.fetchAllAddresses ||
          (data?.fetchAllAddresses.length === 0 && (
            <Box
              sx={{
                height: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={emptyAddress} alt="no_address" />
            </Box>
          ))}
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          sx={{ color: "#333", px: 4, borderBottom: "1px solid #F8F7F7" }}
        >
          <DriveFileRenameOutlineOutlinedIcon sx={{ mr: 1 }} />
          Edit
        </MenuItem>
      </Popover>
    </>
  );
};

export default Address;
