import { Box, List, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { NavData } from '../../assets/Data/NavData';
import { Link, useLocation } from'react-router-dom';

const SideNav = () => {
      const router = useLocation();
  return (
    <>
      <Box>
        <List>
          {NavData.map((nav, id) => (
            <Link to={nav.link} key={id}>
              <ListItemButton
                selected={router.pathname === nav.link}
                sx={{
                  
                  mr: 2,
                  pl: 5,
                  " 	&.Mui-selected": {
                    bgcolor: "#E9FCF9",
                      borderLeft: "4px solid #148B77",
                      '& > span ': {
                          color:'#1a1a1a'
                      }
                  },
                }}
              >
                <ListItemText sx={{color:'#666', }}>{nav.title}</ListItemText>
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    </>
  );
}

export default SideNav