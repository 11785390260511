import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuList,
  Select,
  TextField,
  Typography,
  MenuItem,
  Divider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useMutation,useLazyQuery } from "@apollo/client";
import { PROFILE_EDIT } from "../../Utils/Mutations";
import LoadButton from "../../Components/Common/LoadButton";
import { GET_COUNTRIES, GET_PROFILE } from "../../Utils/Queries";
import Loader from "../../Components/Common/Loader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
};



const Profile = () => {
  const userProfile = useSelector((state) => state.user?.user);
  const uuid = useSelector((state) => state.user?.uuid);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState("");
    const [editFirstName, setEditFirstName] = useState("");
    const [editLastName, setEditLastName] = useState("");
  const [getProfile, { loading: profileLoading, data: profileData }] =
  useLazyQuery(GET_PROFILE);


    const [response, setResponse] = useState("");
    const [country, setCountryId] = useState("");





    const handleChange2 = (event) => {
      setValue2(event.target.value);
    };

    const handleGetProfile = () => {
      getProfile()
        .then((response) => {
    setEditFirstName(response?.data?.getProfile?.profile?.firstName)
          setEditLastName(response?.data?.getProfile?.profile?.lastName)
          setValue(response?.data?.getProfile?.profile?.sex);
          setValue2(response?.data?.getProfile?.profile?.country?.name);
          setCountryId(response?.data?.getProfile?.profile?.country?._id);
        }).catch((err) => {
        
      })
        
      }
  useEffect(() => {
    getCountries();
    handleGetProfile()
    }, []);
  
      const { firstName, lastName, email, phone, sex } =
        profileData?.getProfile?.profile ?? [];
  
  const [getCountries, { loading:countryLoader, data }] = useLazyQuery(GET_COUNTRIES);
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [ editProfile, { loading, error, }
   ] =  useMutation(PROFILE_EDIT)

  //  console.log("Vslue", profileData.getProfile.profile)
  const handleEditProfile = () => {
    editProfile({
      variables: {
        firstName: editFirstName,
        lastName: editLastName,
        sex: value,
        country:country
      },
    })
      .then((response) => {
        console.log(response);
        setOpen(false)
     handleGetProfile()
      })
      .catch((error) => {
        console.log(error);
      });
  }


  console.log("Edit", editFirstName)
  return (
    <>
      {countryLoader && <Loader />}
      <Box sx={{ bgcolor: "#fff", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #F2F2F2",
            p: 1,
          }}
        >
          <Typography>Profile Details</Typography>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ borderRadius: "24px" }}
          >
            Edit
          </Button>
        </Box>
        <Box sx={{ mt: 2, p: 1 }}>
          <Grid container>
            <Grid item lg={6}>
              <Box>
                <Typography sx={{ fontSize: "14px" }}>First Name</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {firstName ? firstName : "--"}
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "14px" }}>Email Adress</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {email ? email : "--"}
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "14px" }}>Phone Address</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {phone ? `+${phone}` : "--"}
                </Typography>
              </Box>
              <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontSize: "14px" }}>Gender</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {sex ? sex : "--"}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Box sx={{ mt: 0 }}>
                <Typography sx={{ fontSize: "14px" }}>Last Name</Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#1a1a1a" }}
                >
                  {lastName ? lastName : "--"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box align="center" sx={{ mt: 2, bgcolor: "#fff", p: 3 }}>
        <Button sx={{ color: "#FF3347", textDecoration: "underline" }}>
          Delete my account
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, color: "#1a1a1a" }}>
              Edit Profile
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: "#1a1a1a" }} />
            </IconButton>
          </Box>

          <Box sx={{ mt: 3 }}>
            <TextField
              fullWidth
              value={editFirstName}
              onChange={(e) => setEditFirstName(e.target.value)}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <TextField
              fullWidth
              value={editLastName}
              onChange={(e) => setEditLastName(e.target.value)}
              InputProps={{
                style: {
                  //   fontFamily: "Gordita",
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "13px",
                  borderRadius: "8px",
                  offset: " 1px solid #f2f2f2",
                  // Replace with your desired font family
                },
              }}
            />
            <Grid container spacing={2}>
              <Grid item lg={6}>
                <Box>
                  {/* <Typography sx={{ fontSize: "14px", ml: 2 }}>Sex</Typography> */}
                  <Select
                    value={value}
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      fontSize: "13px",
                      // border: "0.5px solid #671E78",
                      fontFamily: "inter",
                      borderRadius: "10px",
                    }}
                    inputProps={{
                      "aria-label": "With label",
                      style: {
                        color: "#2B2B2B",
                        fontSize: "13px",
                        fontWeight: 600,
                        mt: 1.5,
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select Sex
                    </MenuItem>
                    <MenuItem value="MALE">Male</MenuItem>
                    <MenuItem value="FEMALE">Female</MenuItem>
                    <MenuItem value="NONE">Other</MenuItem>
                  </Select>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box>
                  {/* <Typography sx={{ fontSize: "14px" }}>Country</Typography> */}
                  <Select
                    // size="small"
                    value={value2}
                    onChange={handleChange2}
                    fullWidth
                    sx={{
                      fontSize: "13px",
                      // border: "0.5px solid #671E78",
                      fontFamily: "inter",
                      borderRadius: "10px",
                    }}
                    inputProps={{
                      "aria-label": "With label",
                      style: {
                        color: "#2B2B2B",
                        fontSize: "13px",
                        fontWeight: 600,
                        mt: 1.5,
                      },
                    }}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      Select Country
                    </MenuItem>
                    {data?.getAllCountries?.items.map(({ name, _id }) => (
                      <MenuItem
                        value={name}
                        onClick={(e) => {
                          setCountryId(_id);
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <LoadButton
              text="save"
              text2="saving"
              action={handleEditProfile}
              isLoading={loading}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Profile;
