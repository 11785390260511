import React from 'react'
import Navbar from '../../Components/Common/Navbar'
import { Box, Typography, Grid } from '@mui/material'
import BlogCard from '../../Components/Cards/BlogCard'
import blogImg from '../../assets/blog.png'
import Newsletter from '../../Components/Common/Newsletter'
import Footer from '../../Components/Common/Footer'

const newsData = [
  {
    img: blogImg,
    title: "Blog Post Title",
    caption:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu...",
  },
  {
    img: blogImg,
    title: "Blog Post Title",
    caption:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu...",
  },
  {
    img: blogImg,
    title: "Blog Post Title",
    caption:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu...",
  },
  {
    img: blogImg,
    title: "Blog Post Title",
    caption:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu...",
  },
  {
    img: blogImg,
    title: "Blog Post Title",
    caption:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentiu...",
  },
];
const Blogs = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { lg: 14, md: 14, sm: 10, xs: 9 },
       margin:'0 auto',
       width:{lg:'85%', md:'85%', sm:'95%', xs
      :'95%'},
          p: { lg: 5, md: 5, sm: 3, xs: 0 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 500, color: "#333" }}>
          Blog
        </Typography>
        <Box mt={4}>
          <Grid container spacing={2} sx={{ mt: 0, }}>
            {newsData.map(({ img, title, caption }, index) => (
              <Grid item lg={3} md={3} sm={6} xs={12} key={index}>
                <BlogCard image={img} title={title} caption={caption} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Newsletter/>
      <Footer/>
    </>
  );
}

export default Blogs