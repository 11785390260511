import React from 'react'
import Navbar from '../Components/Common/Navbar'
import { Box, Typography } from '@mui/material';
import Newsletter from '../Components/Common/Newsletter';
import Footer from '../Components/Common/Footer';

const Ads = () => {
  return (
    <>
      <Navbar />
      <Box
        sx={{
          mt: { lg: 14, md: 14, sm: 10, xs: 10 },
          bgcolor: "#fff",
          p: { lg: 5, md: 5, sm: 3, xs: 2 },
          mb: 3,
        }}
      >
        <Typography sx={{ fontSize: "24px", fontWeight: 500, color: "#333" }}>
         Ads
        </Typography>
        <Typography
          sx={{ fontSize: "14px", fontWeight: 200, color: "#333", mt: 2 }}
        >
          Lorem ipsum dolor sit amet consectetur. Ipsum sit turpis etiam cursus
          sed tincidunt nisi. Curabitur imperdiet ut nunc id pellentesque enim
          eu augue diam. Fames mattis purus posuere penatibus erat ultrices
          varius. Convallis pellentesque amet varius ac laoreet arcu ac dui.
          Velit euismod tellus adipiscing vel accumsan massa. Euismod faucibus
          ipsum sagittis id nisi pretium integer diam condimentum. Tortor massa
          eget gravida condimentum scelerisque senectus augue. Ut tortor
          faucibus pretium nisi dui senectus malesuada lectus elementum. Aliquet
          fames erat cursus sed. Viverra amet pellentesque odio sed aliquam.
          Etiam dictum tellus in sollicitudin. Lorem integer arcu magna odio
          vitae. Molestie convallis augue mauris arcu sit nunc diam mi eget.
          Egestas ultricies enim massa euismod. Ac massa enim etiam pulvinar
          mauris arcu aliquam curabitur. Arcu sagittis sem eget quam urna ipsum
          leo porttitor. Nulla turpis accumsan adipiscing et sed purus cras.
          <br />
          <br />
          Dignissim mattis praesent nec placerat justo. Ullamcorper quis nec
          donec urna tellus cras in tristique. Bibendum egestas faucibus vel eu
          quis pellentesque in at mi. Ut neque aliquet aliquet eu ut sed
          interdum in arcu. Pharetra morbi duis est libero dictum morbi id risus
          egestas. Sit phasellus id faucibus in ultrices tristique risus nisl
          consectetur. Et in cursus suspendisse risus. Nunc semper vestibulum
          neque eget adipiscing suspendisse sed nam elit. Diam ut et eu cursus
          consequat non ultricies interdum. In neque ut at in. Nisi facilisi
          nascetur malesuada pulvinar purus. Adipiscing ante feugiat tristique
          in vestibulum cursus ultrices. Blandit ac interdum ut vestibulum vitae
          aliquam convallis id sodales. At in iaculis malesuada vel ut viverra
          velit. Rutrum enim a sociis pulvinar et. Faucibus eget vulputate proin
          phasellus urna ut scelerisque. Lobortis commodo quis in amet id mattis
          natoque mauris. Orci purus ultrices in ornare bibendum tortor. Id
          tellus arcu amet purus aenean dignissim. Aliquam morbi sagittis sed ac
          bibendum donec at. Vitae neque tortor cursus id mauris sem elit
          sagittis turpis. Leo cursus non morbi arcu.
          <br />
          <br /> Dolor cras massa adipiscing leo velit platea nisl et aenean.
          Nibh ut ut leo et faucibus pharetra aliquet dignissim ullamcorper.
          Diam nisl viverra iaculis amet. Sed blandit quis at sit et molestie
          porttitor. Congue dictum aliquam egestas aenean risus. Morbi mauris
          amet cras vel nibh suscipit morbi aliquet montes. Netus malesuada sem
          sit arcu. Nunc egestas dui nec duis etiam sed in platea. Suscipit sed
          velit sed mi. Quam augue odio massa diam nulla sapien in dis. Risus
          risus sagittis eget et eu aliquet. Ipsum nunc purus dignissim tortor
          risus vestibulum at nunc gravida. Magna congue sem erat vitae sed
          facilisis volutpat. Quis pulvinar pharetra vitae nec suspendisse est
          pellentesque tempus est. Quis nunc integer vitae diam facilisis cras
          tincidunt. Neque elementum tincidunt urna mauris in egestas commodo
          pulvinar sagittis. Vel sem tincidunt pharetra viverra leo euismod odio
          ac quis. Turpis lectus vulputate sed in cursus est metus. Platea
          faucibus eu nisi porta. Quam id euismod elementum placerat diam enim
          proin tincidunt. Et mi diam fames amet fermentum netus ornare urna
          urna. Erat nec viverra nunc nullam nisi varius egestas pharetra. In
          placerat dolor platea velit amet. Justo nulla maecenas et nulla
          blandit nulla integer id. Imperdiet est elementum amet feugiat tortor
          adipiscing etiam. In est tellus libero faucibus morbi sit amet quis
          sapien. Faucibus eu purus dignissim purus. Rhoncus sapien nibh luctus
          quis viverra etiam suspendisse vivamus facilisis. Mattis eget
          tristique interdum lobortis orci proin gravida. Nisl in sed elit nisi
          bibendum ultrices mattis convallis consequat. Et etiam enim congue ut
          pulvinar malesuada. Vitae vitae pulvinar nisl vitae libero. Morbi
          aliquet amet amet dolor iaculis. Consequat habitant turpis est laoreet
          viverra. Purus volutpat sit ultrices tempor ipsum viverra eget
          suspendisse nec. Tempor lorem vitae amet eros amet at duis. Nibh sed
          id laoreet sed diam at tempor aenean.
        </Typography>
      </Box>
      <Newsletter/>
      <Footer/>
    </>
  );
}

export default Ads