import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Typography,
  IconButton,
  List,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import sendIcon from "../../src/assets/Icons/send-2.svg";
import addIcon from "../../src/assets/Icons/add-square.svg";
import TextField from "@mui/material/TextField";
import "../STYLES/main.css";
// import Modal from "@mui/material/Modal";
// import uploadIcon from '../../src/assets/Icons/upload.svg'
import { useParams } from "react-router-dom";
import socket from "../Utils/socket";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 500,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   height:400,
//   pt: 4,
//   display: 'flex',
//   flexDirection:'column'
// };



const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CssTextField = styled(TextField)({
  background: "#f2f2f2",
  borderRadius: "8px",
  padding: "0px",
  "& label.Mui-focused": {
    color: "#A0AAB4",
    bgcolor: "#F2F2F2",
    padding: "0px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#f2f2f2",
    bgcolor: "#F2F2F2",
    padding: "0px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#666",
      borderColor: "#f2f2f2",
      bgcolor: "#F2F2F2",
      padding: "0px",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
      padding: "0px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
      padding: "0px",
    },
  },
});
const ChatOutlet = () => {
  const { id } = useParams();
    const [uniqueId, setUniqueId] = useState(null);
      const messagesContainerRef = useRef(null);
const [message, setMessage] = useState("")
const [chatLoad, setChatLoad] = useState(false)
    const generateUniqueId = () => {
      const newUniqueId = uuidv4();
      setUniqueId(newUniqueId);
      console.log(typeof(uniqueId))
    };

  const [open, setOpen] = React.useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const [otherUserName, setOtherUserName] = useState(null)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading , setIsLoading] = useState(false)

  const userId = useSelector((state) => state.user.user.profile._id);
  console.log(userId);
  // const [progress, setProgress] = useState(0);
  // const [fileSize, setFileSize] = useState("");
  // const [fileName, setFileName] = useState("");
  // const [thumbnailUrl, setThumbnailUrl] = useState("");

  // const bytesToSize = (bytes) => {
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  //   if (bytes === 0) return "0 Byte";
  //   const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  //   return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  // };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     const reader = new FileReader();

  //     reader.onprogress = (event) => {
  //       if (event.lengthComputable) {
  //         const percentage = (event.loaded / event.total) * 100;
  //         setProgress(percentage);
  //       }
  //     };

  // reader.onload = (event) => {
  // Handle the file data here (e.g., send it to a server)
  // event.target.result contains the file data
  // console.log(file, "=> file");
  // Set the file size in KB or MB
  // setFileSize(bytesToSize(file.size));

  // Set the file name
  // setFileName(file.name);

  // For the thumbnail, you can create a data URL for an image if the file is an image
  // if (file.type.startsWith("image/")) {

  // console.log(thumbnailUrl)
  // const blob = new Blob([event.target.result], { type: file.type });
  // const dataUrl = URL.createObjectURL(blob);

  // Set the data URL as the image source
  //        setThumbnailUrl(dataUrl);
  //   }
  // };

  // Read the file as an ArrayBuffer (you can change this based on your needs)
  //     reader.readAsArrayBuffer(file);
  //   }
  // };

  // ALL THE CODES ABOVE ARE FOR FILE UPLOAD

  const JOIN = "joinRoom";
  const EXCEPTION = "exception";
  const JOINED_ROOM = "joinedRoom";
  const SEND_MESSAGE = "sendMessage";
  const RECEIVED_MESSAGE = "receivedMessage";
  const RECIEVED_LAST_MESSAGE = "receivedLastMessage";
// console.log(message)
  // console.log(id, '=>{RommCHan}}')

         const payLoad = {
           roomChannel: id,
           operationMode: "Product",
         };



  function convertUTCToLocalTime(utcTimestamp, timeFormat) {
    const date = new Date(utcTimestamp);
    const localTime = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    if (timeFormat === "12-hour") {
      // Return time in 12-hour format
      return localTime;
    } else if (timeFormat === "24-hour") {
      // Return time in 24-hour format
      return localTime.replace(/(am|pm)/i, "").trim();
    } else {
      // Invalid time format
      return "Invalid time format";
    }
  }

    useEffect(() => {
      setChatLoad(true)
      generateUniqueId();
      socket.emit(JOIN, payLoad);
      socket.on(JOINED_ROOM, (data) => {
        // console.log(data.otherUsers, "=>Joined room");
        
        setMessageHistory(data?.otherUsers?.messages);
        setOtherUserName(data?.otherUsers?.otherUserName);
        setChatLoad(false)
      },[id]);


      socket.on(EXCEPTION, (err) => {
        console.log(err);
      });
      return () => {
        socket.off(EXCEPTION);
        socket.off(JOINED_ROOM);
      };
    }, []);

      const handleKeyDown = (event) => {
      
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
          handleSendMessage();
        }
      };

  const handleSendMessage = () =>{
    setIsLoading(true)
generateUniqueId()

const chatPayload = {
  roomChannel: id,
  message: message,
  clientMessageId: uniqueId,
};

socket.emit(SEND_MESSAGE, chatPayload)
            socket.on(JOINED_ROOM, (data) => {
              alert("yes");
              setMessageHistory([
                ...messageHistory,
                data?.otherUsers?.lastMessages?.message,
              ]);
            });

  setIsLoading(false);
  setMessage("");
  socket.on(RECEIVED_MESSAGE, (data)=>{
    console.log(data, "RECIEVED MESSAGE")
    setMessageHistory((prev) =>[...prev, data])
  })
  socket.on(RECIEVED_LAST_MESSAGE, (data)=>{
    console.log(data, "RECIEVED LAST MESSAGE")
  })
// handleChatHistory();
  
  }

    useEffect(() => {
      // Scroll to the bottom of the div when messages change
  
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
      

    }, [messageHistory]);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            bgcolor: "#e6e6e6",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", columnGap: 2 }}>
            <Badge
              variant="dot"
              color="primary"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar />
            </Badge>
            <Box>
              <Box>
                <Typography
                  sx={{ color: "#000", fontWeight: 500, fontSize: "16px" }}
                >
                  {otherUserName ? otherUserName : "user"}
                </Typography>
                <Typography
                  sx={{ fontSize: "11px", mt: 0, lineHeight: 0, mt: 0.5 }}
                >
                  Online
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Button sx={{ bgcolor: "#fff", color: "#000" }}>
              Order details
            </Button>
          </Box>
        </Box>
        <Box align="center" sx={{ bgcolor: "#f2f2f2b7" }}>
          <Box sx={{mt:2}}>{chatLoad && <CircularProgress />}</Box>
          <Typography
            sx={{
              bgcolor: "#F2F2F2",
              width: "fit-content",
              px: 2,
              fontSize: "11px",
              color: "#333",
              borderRadius: "100px",
            }}
          >
            {`  You are now chatting with ${
              otherUserName ? otherUserName : "user"
            }`}
          </Typography>
        </Box>
        <Box
          ref={messagesContainerRef}
          sx={{
            p: 2,
            bgcolor: "#f2f2f2b7",
            height: "290px",
            pb: 2,
            overflowY: "auto",
          }}
        >
          <Box align="center">
            <Typography
              sx={{
                bgcolor: "#F2F2F2",
                width: "fit-content",
                borderRadius: "100px",
                px: 2,
                fontSize: "11px",
                color: "#333",
              }}
            >
              Today
            </Typography>
          </Box>

          {messageHistory &&
            messageHistory.map((chat, index) => (
              <>
                {chat.sender == userId ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "right",
                      alignItems: "end",
                      mt: 2,
                    }}
                  >
                    {chat.messageType === "offer" ? (
                      <>
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: "30%",
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                color: "#333",
                                fontSize: "11px",
                                lineHeight: "20px",
                              }}
                            >
                              {chat.message}
                            </Typography>
                            <Button
                              sx={{
                                bgcolor: "#3FB763",
                                color: "#fff",
                                mt: 1,
                                px: 5,
                              }}
                            >
                              Buy Now
                            </Button>
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#333", fontSize: "9px" }}>
                              {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: "30%",
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "11px",
                              lineHeight: "20px",
                            }}
                          >
                            {chat.message}
                          </Typography>

                          <Typography sx={{ color: "#333", fontSize: "9px" }}>
                            {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "right",
                      // alignItems: "end",
                      mt: 2,
                    }}
                  >
                    {chat.type === "offer" ? (
                      <>
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: "30%",
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Box>
                            <Typography
                              sx={{
                                color: "#333",
                                fontSize: "11px",
                                lineHeight: "20px",
                              }}
                            >
                              {chat.message}
                            </Typography>
                            <Button
                              sx={{
                                bgcolor: "#3FB763",
                                color: "#fff",
                                mt: 1,
                                px: 5,
                              }}
                            >
                              Buy Now
                            </Button>
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#333", fontSize: "9px" }}>
                              {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            bgcolor: "#F2F2F2",
                            p: 1,
                            maxWidth: "30%",
                            width: "fit-content",
                            display: "flex",
                            columnGap: 2,
                            alignItems: "flex-end",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#333",
                              fontSize: "11px",
                              lineHeight: "20px",
                            }}
                          >
                            {chat.message}
                          </Typography>
                          <Box>
                            <Typography sx={{ color: "#333", fontSize: "9px" }}>
                              {convertUTCToLocalTime(chat.updatedAt, "12-hour")}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </>
            ))}
        </Box>
        <Box
          sx={{
            border: "1px solid #f2f2f2",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <IconButton onClick={handleOpen}>
            <img src={addIcon} alt="add_icon" />
          </IconButton> */}
          <CssTextField
            onKeyDown={handleKeyDown}
            fullWidth
            multiline
            maxRows={2}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            size="small"
            placeholder="Message"
            id="custom-css-outlined-input"
          />
          <IconButton onClick={handleSendMessage}>
            {isLoading ? <p>....</p> : <img src={sendIcon} alt="send_icon" />}
          </IconButton>
        </Box>
      </Box>

      {/* <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                textAlign: "center",
                color: "#333",
                fontSize: "20px",
              }}
            >
              Upload File
            </Typography>
            <Box
              sx={{
                mt: 4,
                mx: 4,
                border: "1px dashed #148B77",
                p: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "10px",
              }}
            >
              <img src={uploadIcon} width={30} alt="upload_icon" />
              <Box>
                <Typography sx={{ color: "#333", fontSize: "13px" }}>
                  Select a file or drag and drop here
                </Typography>
                <Typography sx={{ color: "#666", fontSize: "11px" }}>
                  JPG, PNG or PDF, file size no more than 10MB
                </Typography>
              </Box>
              <Button size="small" component="label">
                SELECT FILE
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
            </Box>
            {progress > 0 && (
           
              <Box sx={{ mt: 2, mx: 2 }}>
                <Typography sx={{ color: "#333", fontSize: "11px" }}>
                  File added
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: 2,
                    width: "100%",
                  }}
                >
                  <Avatar variant="square" src={thumbnailUrl} />
                  <Box sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ color: "#333", fontSize: "11px" }}>
                        {fileName}
                      </Typography>
                      <Typography sx={{ color: "#333", fontSize: "11px" }}>
                        {fileSize}
                      </Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={progress} />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            align="right"
            sx={{
              mt: "auto",
              bgcolor: "#FBFDFE",
              p: 2,
              boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.10) inset",
            }}
          >
            <Box>
              <Button sx={{ color: "#333", mr: 2 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained">Upload</Button>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </>
  );
};

export default ChatOutlet;
