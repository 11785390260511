import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';

const breadcrumbNameMap = {
    '/product-view': 'Products',
    '/inbox/important': 'Important',
  '/category-view': 'category-view',
    '/account':'My Account',
    '/account/profile':'Profile',
    '/account/notification':'Notification',
    '/account/mamapurse':'Mamapurse',
    '/account/orders':'My Orders',
    '/account/security':'Security',
    '/account/help-center':'Help Center',
    '/account/address':'Address',

  };




  
  function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
  }

const BreadCrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
  return (
<>
<Box sx={{p:1, bgcolor:'#fff',fontFamily:'inter'}}>
<Breadcrumbs aria-label="breadcrumb"   separator={<NavigateNextIcon fontSize="small" />}>
      <LinkRouter underline="hover" color="inherit" to="/my-account" style={{fontFamily:'inter'}}> 
        Home
      </LinkRouter>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <Typography color="text.primary" key={to} sx={{fontWeight:400, fontFamily:'inter'}}>
            {breadcrumbNameMap[to]}
          </Typography>
        ) : (
          <LinkRouter underline="hover" color="inherit" to={to} key={to} sx={{fontFamily:'inter'}}>
            {breadcrumbNameMap[to]}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>

</Box>
</>
  )
}

export default BreadCrumbs
