import React, { useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
  pt: 6,
};

const SuccessAlert = ({ img, header, text, url, button, open }) => {
  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} align="center">
          <img src={img} alt="successful" width={100} />

          <Typography
            sx={{ fontWeight: 600, color: "#1a1a1a", fontSize: "20px", mt: 4 }}
          >
            {header}
          </Typography>

          <Typography
            sx={{ fontWeight: 200, color: "#1a1a1a", fontSize: "13px", mt: -1 }}
          >
            {text}
          </Typography>
          <Link to={`/${url}`}>
            <Button
              variant="contained"
              fullWidth
              sx={{ py: 1, mt: 3, borderRadius: "50px" }}
            >
              {button}
            </Button>
          </Link>
        </Box>
      </Modal>
    </>
  );
};

export default SuccessAlert;
