import {
  AppBar,
  Box,
  Grid,
  Typography,
  Badge,
  IconButton,
  Avatar,
  Popover,
  Menu,
  MenuItem,
  ListItemButton,
  Button,
  useMediaQuery,
  useTheme

} from "@mui/material";
import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import { Link, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import userIcon from "../../assets/Icons/user.svg";
import ChatIcon from "@mui/icons-material/Chat";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import profile from "../../assets/Icons/Profile.svg";
import box from "../../assets/Icons/box.svg";
import wallet from "../../assets/Icons/Wallet.svg";
import LanguageTranslator from "./LanguageTranslator";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../../store/userSlice";
import Drawers from "./Drawers";
import { fetchCart } from "../../store/cartsSlice";
import { selectCartItems } from "../../store/cartAsync";
import { fetchCartAsync } from "../../store/cartAsync";



const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const Navbar = () => {
  const navigate = useNavigate()
const dispatch = useDispatch()
  const cartProducts = useSelector(state => state.cart)
  const cartItems = useSelector((state)=>state.cartAsync.items);
 console.log(cartItems, "CartItems");
  
  const favProducts = useSelector(state => state.favourite)
   const isAuthenticated = useSelector((state) => state.user.user);
   const theme = useTheme()
   const userInfo = useSelector((state)=>state.user?.user?.profile)

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchCartAsync());
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    navigate("/")
      setAnchorEl(null);
dispatch(logoutUser())
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <AppBar sx={{ bgcolor: "#fff", boxShadow: "none" }}>
        {isMobile ? (
          <>
            <Drawers />
          </>
        ) : (
          <>
            <Box
              sx={{
                bgcolor: "#F8F7F7",
                py: 1,
                margin: "0 auto",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "90%",
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="auto-moving-text-container">
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", md: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#148B77" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", md: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#148B77" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                  <div className="auto-moving-text">
                    <Typography
                      sx={{
                        fontFamily: "inter",
                        fontWeight: 400,
                        color: "#1a1a1a",
                        fontSize: "12px",
                        display: { lg: "block", xs: "none" },
                      }}
                    >
                      Shop and sell on the go, Get it now!!{" "}
                      <Link
                        to="/"
                        style={{ textDecoration: "none", color: "#148B77" }}
                      >
                        Download Mamaket App
                      </Link>
                    </Typography>
                  </div>
                </div>
              </Box>
            </Box>
            <Grid container sx={{ py: 1, margin: "0 auto", width: "85%" }}>
              <Grid
                item
                lg={7}
                md={7}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Logo variant="text" width="120" />
              </Grid>
              <Grid
                item
                lg={5}
                md={5}
                // align="right"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box>
                  <LanguageTranslator />
                </Box>
                {isAuthenticated ? (
                  <>
                    <Box sx={{ display: "flex", columnGap: 2 }}>
                        <Link to="/cart">
                      <IconButton sx={{}}>
                          <StyledBadge
                            badgeContent={
                              isAuthenticated
                                ? cartItems && cartItems.length
                                : cartProducts.length
                            }
                            color="error"
                          >
                            <ShoppingCartIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </StyledBadge>
                      </IconButton>
                        </Link>
                        <Link to="/wishlist">
                      <IconButton>
                          <StyledBadge
                            badgeContent={favProducts?.length}
                            color="error"
                          >
                            <FavoriteIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </StyledBadge>
                      </IconButton>
                        </Link>
                      <Link to="/chat">
                        <IconButton>
                          <StyledBadge badgeContent={0} color="error">
                            <ChatIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </StyledBadge>
                        </IconButton>
                      </Link>
                      <IconButton>
                        <StyledBadge badgeContent={1} color="error">
                          <NotificationsIcon
                            color="action"
                            sx={{ color: "#000", fontSize: "20px" }}
                          />
                        </StyledBadge>
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={userIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleClick}
                      >
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              fontFamily: "inter",
                              fontWeight: 500,
                              color: "#1a1a1a",
                              pr: 0.5,
                              fontSize: "12px",
                            }}
                          >
                            {userInfo
                              ? `${userInfo?.firstName} ${userInfo?.lastName}`
                              : "My Account"}
                          </Typography>
                        </Box>
                        <KeyboardArrowDownIcon sx={{ color: "#1a1a1a" }} />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>
                      <IconButton sx={{ mr: 3 }}>
                        <Link to="/cart">
                          <Badge
                            badgeContent={
                              isAuthenticated
                                ? cartItems && cartItems.length
                                : cartProducts.length
                            }
                            color="error"
                          >
                            <ShoppingCartIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </Badge>
                        </Link>
                      </IconButton>
                      <Link to="/wishlist">
                        <IconButton>
                          <Badge badgeContent={0} color="error">
                            <FavoriteIcon
                              color="action"
                              sx={{ color: "#000", fontSize: "20px" }}
                            />
                          </Badge>
                        </IconButton>
                      </Link>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: 3,
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        src={userIcon}
                        sx={{ width: "20px", height: "20px" }}
                      />
                      <Box>
                        <Link to="/login">
                          <Typography
                            variant="caption"
                            sx={{
                              fontFamily: "inter",
                              fontWeight: 500,
                              color: "#1a1a1a",
                              borderRight: "2px solid #1a1a1a",
                              pr: 2,
                              fontSize: "12px",
                            }}
                          >
                            Login
                          </Typography>
                        </Link>
                        <Link to="/register">
                          <Typography
                            variant="caption"
                            sx={{
                              fontFamily: "inter",
                              fontWeight: 500,
                              color: "#1a1a1a",
                              pl: 2,
                              fontSize: "12px",
                            }}
                          >
                            Register
                          </Typography>
                        </Link>
                      </Box>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ width: "200px" }}>
          <Link to="/account">
            <MenuItem
              sx={{ borderBottom: "1px solid #F8F7F7", mb: 1, color: "#333" }}
            >
              <img src={profile} style={{ marginRight: "10px" }} />
              My Account
            </MenuItem>
          </Link>
          <Link to="/account/mamapurse">
            <MenuItem
              sx={{ borderBottom: "1px solid #F8F7F7", mb: 1, color: "#333" }}
            >
              <img src={wallet} style={{ marginRight: "10px" }} />
              Mamapurse
            </MenuItem>
          </Link>
          <Link to="/account/orders">
            <MenuItem
              sx={{ borderBottom: "1px solid #F8F7F7", mb: 1, color: "#333" }}
            >
              <img src={box} style={{ marginRight: "10px" }} />
              My Order
            </MenuItem>
          </Link>
          <Button
            onClick={handleLogOut}
            sx={{
              color: "#FF3347",
              textAlign: "center",
              fontWeight: 300,
              fontFamily: "inter",
            }}
            fullWidth
          >
            Log out
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Navbar;
